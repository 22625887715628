<template>
    <div class="py-4 container-fluid ">
        <div class="card" style="text-align: center;margin: auto; ">
            <i class="text-danger" style="font-size: 110px;">
                <font-awesome-icon icon="fa-solid fa-triangle-exclamation " />
            </i>
            <h1 style="font-size:100px">404</h1>
            <h4>Error, Page Not Found!</h4>
            <p>Please contact IT! (ANG WEI ZE)</p>
        </div>
    </div>
</template>