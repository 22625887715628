<script setup>
// import { ref } from 'vue';
// import SawitBuahTable from "./Tables/SawitBuahTable.vue";
import ProductTable from "./Tables/ProductTable.vue";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Product</h6>
                </div>
                <div class="container-fluid">
                    <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Prodcut Name</label>
                            <input class="form-control" v-model="model.product.name" id="input_block_name" type="text" placeholder="Sawit" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Unit Price (RM)</label>
                            <input class="form-control" v-model="model.product.price" id="input_weight" type="number" placeholder="17" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Category</label>
                            <select class="form-control" v-model="model.product.category">
                                <option value="0">Sawit</option>
                                <option value="1">Getah</option>
                                <option value="2">Durian</option>
                                <option value="3">Baja</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Detail</label>
                            <input class="form-control" v-model="model.product.detail" id="input_price" type="text" placeholder="1 Tingkat,2 Tingkat" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success" @click="addProduct" style="float: right;padding-top:10px" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="mt-4 row">
        <div class="col-md-12">
            <ProductTable :key="componentKey" />
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'productCreate',
  data(){
    return {
        componentKey: 0,
        sawitBuahScheduleKey: 0,
    //   errorList: '',
      model:{
        product:{
            name:'',
            category:'0',
            detail:'',
            price:''
        },
        

      }
    }
  },
  methods: {
    addProduct(){
        // var mythis = this;
        console.log(JSON.stringify(this.model.product));
        axios.post('product/addProduct',this.model.product)
            .then(res => {
                alert(res.data.message);
                // this.uploadImage();
                this.model.product = {
                    name:'',
                    detail:'',
                    category:'0',
                    price:'',
                };
                this.componentKey += 1;
                this.sawitBuahScheduleKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block id, Weight and Date is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>