<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Customer table</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder">
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder ps-2">
                  Address
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">
                  Remark
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">
                  Status
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">
                  Date Created
                </th>
                <th class="text-secondary"></th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr>
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <img
                        src="../../assets/img/team-2.jpg"
                        class="avatar avatar-sm me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">John Michael</h6>
                      <p class="text-xs text-secondary mb-0">
                        john@creative-tim.com
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">Manager</p>
                  <p class="text-xs text-secondary mb-0">Organization</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">Online</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >23/04/18</span
                  >
                </td>
                <td class="align-middle">
                  <a
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    >Edit</a
                  >
                </td>
              </tr> -->
              <tr v-for="(customer,index) in this.customers" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ customer.name }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ customer.contact}}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ customer.company }}</h6>
                  <p class="text-xs text-secondary mb-0">{{ customer.address }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ customer.remark }}</p>
                  <p class="text-xs text-secondary mb-0">{{ customer.cust_info }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <!-- {{ customer.status}} -->
                  <span v-if="customer.status == 'Open' || customer.status == 'Following' || customer.status == 'Success'" class="badge badge-sm bg-gradient-success text-xs">{{ customer.status}}</span>
                  <span v-else-if="customer.status == 'Failed'" class="badge badge-sm bg-gradient-danger text-xs">{{ customer.status}}</span>
                  <span v-else class="badge badge-sm bg-gradient-info text-xs">{{ customer.status}}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold" >
                    {{ customer.created_at }}
                    </span>
                </td>
                <td class="align-middle">
                  <RouterLink :to="{path : '/EditCustomer/'+ customer.customerid}"
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    >Edit
                  </RouterLink>
                </td>
              </tr>
              
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
<script>
import axios from 'axios';

export default {
  name:'customerSelect',
  data(){
    return {
      customers: []
    }
  },
  mounted(){
    this.getCustomerList();
  },
  methods: {

    getCustomerList(){
        console.log(localStorage.getItem('token'));
        // var mythis = this;
        axios.get('customer/listAllDetails')
            .then(res => {
              console.log(res.data.customer);
                this.customers = res.data.customer;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
  },
}
</script>