<script setup>
import { onMounted, ref } from "vue";
import { Modal } from 'bootstrap';
import axios from 'axios';
// const modalActive = ref(false);
// const toggleModal = () =>{
//     modalActive.value = !modalActive.value;
// };

// import ArgonButton from "@/components/ArgonButton.vue";
// let sewaProfileModal;
let editSewaProfileModal;
const sewaprofilelist = ref([]);
onMounted(() => {
    getSewaProfileList();
    // sewaProfileModal = new Modal(document.getElementById('sewaProfileModal'));
    editSewaProfileModal = new Modal(document.getElementById('editSewaProfileModal'));
});
const modelUpdateSewaProfile = ref({
        amount:0,
        remark:'',
        address:'',
        isdelete:'',
        sewaprofileid:'',
    });
const model = {
    sewaProfile: {
        amount:0,
        remark:'',
        address:'',
    },
    // updateSewaProfile: {
    //     amount:0,
    //     remark:'',
    //     address:'',
    //     isdelete:'',
    //     sewaprofileid:'',
    // },
};
const showPopupEditSewaProfile = (sewaprofileid) =>{
    editSewaProfileModal.show();
    console.log(sewaprofileid);
    axios.get('sewa/getSewaProfileByID/' + sewaprofileid)
        .then(res => {
        console.log(res.data.sewaProfileDetail[0]);
        modelUpdateSewaProfile.value.address = res.data.sewaProfileDetail[0].address;
        modelUpdateSewaProfile.value.amount = res.data.sewaProfileDetail[0].amount;
        modelUpdateSewaProfile.value.sewaprofileid = res.data.sewaProfileDetail[0].id;
        modelUpdateSewaProfile.value.remark = res.data.sewaProfileDetail[0].remark;
        modelUpdateSewaProfile.value.isdelete = res.data.sewaProfileDetail[0].isdelete;
        }).catch(function (error){
            
            console.log('Error',error.message);
        });
    // this.getSewaProfileDetail(sewaprofileid);
};

function getSewaProfileList(){
    axios.get('sewa/listAllSewaProfile')
        .then(res => {
        sewaprofilelist.value = res.data.sewaProfile;
        }).catch(function (error){
            
            console.log('Error',error.message);
        });
};
function addSewaProfile(){
    axios.post('sewa/addSewaProfile',this.model.sewaProfile)
    .then(res => {
        alert(res.data.message);
        this.model.sewaProfile = {
            address:'',
            amount:'',
            remark:''
        };
        getSewaProfileList();
    }).catch(function (error){
        if(error.response){
            if(error.response.status == 422){
                alert('Error: Address, remark, and Amount is required! ');
            }
        }else if(error.request){
            console.log(error.request);
        }else{
            console.log('Error',error.message);
        }
    });
};
const emit = defineEmits(['updateSewaProfiledll']);
function updateSewaProfile(){
    console.log(JSON.parse(JSON.stringify(modelUpdateSewaProfile.value)));
    axios.post('sewa/updateSewaProfile',JSON.parse(JSON.stringify(modelUpdateSewaProfile.value)))
    .then(res => {
        alert(res.data.message);
        editSewaProfileModal.hide();
        getSewaProfileList();
        emit('updateSewaProfiledll');
    }).catch(function (error){  
        if(error.response){
            if(error.response.status == 422){
                alert('Error: Address, remark, and Amount is required! ');
            }
        }else if(error.request){
            console.log(error.request);
        }else{
            console.log('Error',error.message);
        }
    });
};
const closeEditSewaProfile = () =>{
    editSewaProfileModal.hide();
};
</script>
<template>
  <!-- Modal -->
  <div class="modal fade" id="sewaProfileModal" tabindex="-1" aria-labelledby="sewaProfileLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sewaProfileLabel">New Sewa Profile</h5>
          <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-2">
                <label for="example-text-input" class="form-control-label">Address</label>
                <input class="form-control" v-model="model.sewaProfile.address" id="input_address" type="text" placeholder="Address" />
            </div>
            <div class="col-md-2">
                <label for="example-text-input" class="form-control-label">Amount</label>
                <input class="form-control" v-model="model.sewaProfile.amount" id="input_amount" type="number" placeholder="80.00" />
            </div>
            <div class="col-md-4">
                <label for="example-text-input" class="form-control-label">Remark</label>
                <input class="form-control" v-model="model.sewaProfile.remark" id="input_remark" type="text" placeholder="Remark..." />
            </div>
            <div class="col-md-1">
                <label for="example-text-input" class="form-control-label">&nbsp;</label>
                <button type="button" class="btn btn-primary " @click="addSewaProfile" >Add</button>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
                <table class="table align-items-center mb-0">
                    <thead>
                        <td class="text-uppercase text-secondary text-sm font-weight-bolder">ID</td>
                        <td class="text-uppercase text-secondary text-sm font-weight-bolder">Address</td>
                        <td class="text-uppercase text-secondary text-sm font-weight-bolder">Amount</td>
                        <td class="text-uppercase text-secondary text-sm font-weight-bolder">Remark</td>
                        <td></td>
                    </thead>
                    <tbody>
                        <tr v-for="(sewaprofilelistitem,index) in sewaprofilelist" :key="index">
                            <td>
                                <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ sewaprofilelistitem.id}}</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h6 class="text-xs font-weight-bold mb-0">{{ sewaprofilelistitem.address }}</h6>
                            </td>
                            <td>
                                <h6 class="text-xs font-weight-bold mb-0">{{ sewaprofilelistitem.amount }}</h6>
                            </td>
                            <td>
                                <h6 class="text-xs font-weight-bold mb-0">{{ sewaprofilelistitem.remark }}</h6>
                            </td>
                            <td class="align-middle">
                                <button class="btn btn-warning" data-bs-target="#editSewaProfileModal" @click="showPopupEditSewaProfile(sewaprofilelistitem.id)" data-bs-dismiss="modal">Edit</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

<!-- Edit Modal -->
<div class="modal fade" style="background-color: gray;" id="editSewaProfileModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalToggleLabel2">Edit Sewa Profile</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Sewa Profile ID</label>
                    <input class="form-control" v-model="modelUpdateSewaProfile.sewaprofileid" id="input_id" type="text" placeholder="id" disabled />
                </div>
                <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Address</label>
                    <input class="form-control" v-model="modelUpdateSewaProfile.address" id="input_address" type="text" placeholder="Address" />
                </div>
                <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Amount</label>
                    <input class="form-control" v-model="modelUpdateSewaProfile.amount" id="input_amount" type="number" placeholder="80.00" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Remark</label>
                    <input class="form-control" v-model="modelUpdateSewaProfile.remark" id="input_remark" type="text" placeholder="Remark..." />
                </div>
                <div class="col-md-3">
                    <label for="example-text-input" class="form-control-label">Is delete</label>
                    <select class="form-control" v-model="modelUpdateSewaProfile.isdelete" id="input_isdelete">
                        <option value="0">Open</option>
                        <option value="1">Delete</option>
                    </select>
                </div>
                <div class="col-md-1">
                    <label for="example-text-input" class="form-control-label">&nbsp;</label>
                    <button type="button" class="btn btn-primary " @click="updateSewaProfile" >Update</button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary" data-bs-target="#sewaProfileModal" @click="closeEditSewaProfile" >Close</button>
        </div>
        </div>
    </div>
</div>
</template>