<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Block Expenses table</h6>
        <div class="row">
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Block ID</label>
                <input class="form-control" v-model="search.blockid" id="input_blockid" type="text" placeholder="A01" />
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date From</label>
                <input type="date" id="input_datefrom" class="form-control" v-model="search.datefrom">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date To</label>
                <input type="date" id="input_dateto" class="form-control" v-model="search.dateto">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label" style="width: 100%;">&nbsp;</label>
                <button class="btn btn-success " style="float:right;" id="btn_search" @click="getBlockExpenses">Search</button>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Total Expenses: RM {{ this.ttlBlockExpenses.ttl_price }}</label>
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Total Baja Exp: RM {{ this.ttlBlockExpenses.ttl_baja_price }}</label>
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Total Baja Nursery: RM {{ this.ttlBlockExpenses.ttl_ispaid_price }}</label>
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Total Baja Baru: RM {{ this.ttlBlockExpenses.ttl_new_purchase_price }}</label>
            </div>
        </div>
        <hr />
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-weight-bold font-weight-bolder">
                  Block
                </th>
                <th
                  class="text-uppercase text-weight-bold font-weight-bolder ps-2">
                  Item
                </th>
                <th
                  class="text-uppercase text-weight-bold font-weight-bolder ps-2">
                  Price
                </th>
                <th
                class="text-uppercase text-weight-bold font-weight-bolder ps-2">
                  Date
                </th>
                <th
                class="text-uppercase text-weight-bold font-weight-bolder ps-2">
                  Remark
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(blockExpenses,index) in this.blockExpenses" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ blockExpenses.blockid }}</h6>
                      <p class="text-xs text-weight-bold  mb-0">
                        {{ blockExpenses.blockname}}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ blockExpenses.expensestype }}</h6>
                  <p class="text-xs text-weight-bold  mb-0">{{ blockExpenses.item }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">RM {{ blockExpenses.price }}</p>
                </td>
                <td>
                  <p class="text-xs text-weight-bold  mb-0">{{ blockExpenses.date }}</p>
                </td>
                <td>
                    <p v-if="blockExpenses.ispaid == 0" class="text-xs font-weight-bold mb-0">Baja Nursery</p>
                    <p v-else class="text-xs font-weight-bold mb-0">Baja Baru (Beli dari orang kampong)</p>
                  <p class="text-xs font-weight-bold mb-0">{{ blockExpenses.remark }}</p>
                </td>
                <td class="align-middle">
                  <RouterLink :to="{path : '/editBlock/'+ blockExpenses.blockid}"
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit Block"
                    >Edit
                  </RouterLink>
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>

  
<script>
import axios from 'axios';

export default {
  name:'blockExpensesSelect',
  data(){
    return {
      blockExpenses: [],
      ttlBlockExpenses: {
        ttl_price: '0',
        ttl_baja_price: '0',
        ttl_new_purchase_price: '0',
        ttl_ispaid_price: '0'
      },
      search:{
          dateto: new Date().toISOString().slice(0,10),
          datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
          blockid: '',
        }
    }
  },
  computed: {
    
  },
  mounted(){
    this.getBlockExpenses();
  },
  methods: {

    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    getBlockExpenses(){
      var blockid = '%';
        if(this.search.blockid != ''){
            blockid = this.search.blockid;
        }
        axios.get('expenses/listBlockExpenses/' + this.search.datefrom + "/" + this.search.dateto + "/" +  blockid)
            .then(res => {
              console.log(res.data.blockExpenses);
              this.blockExpenses = res.data.blockExpenses;
              this.ttlBlockExpenses.ttl_price= res.data.ttlBlockExpenses[0].ttl_price;
              this.ttlBlockExpenses.ttl_baja_price= res.data.ttlBlockExpenses[0].ttl_baja_price;
              this.ttlBlockExpenses.ttl_new_purchase_price= res.data.ttlBlockExpenses[0].ttl_new_purchase_price;
              this.ttlBlockExpenses.ttl_ispaid_price= res.data.ttlBlockExpenses[0].ttl_ispaid_price;
            }).catch(function (error){
                
              console.log('Error',error.message);
            });
    }
  },
}
</script>
  