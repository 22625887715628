<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Vehicle table</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Vehicle No
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Type
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Details
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Created Date
                </th>
                <!-- <th class="text-secondary opacity-7"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(vehicle,index) in this.vehicle" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ vehicle.id }} - {{ vehicle.vehicleno }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    Type: {{ vehicle.type }}<br />
                    Remarks: {{ vehicle.remark }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    Puspakom:  {{ vehicle.puspakom }}<br />
                    Insurance: {{ vehicle.insurance }}<br />
                    RoadTax:  {{ vehicle.roadtax }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    Created: {{ vehicle.created_at }}<br />
                    Updated: {{ vehicle.updated_at }}
                    </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
<script>
import axios from 'axios';

export default {
  name:'VehicleSelect',
  data(){
    return {
        vehicle: []
    }
  },
  mounted(){
    this.getVehicleList();
  },
  methods: {

    getVehicleList(){
        // var mythis = this;
        axios.get('vehicle/listAllVehicle')
            .then(res => {
                this.vehicle = res.data.vehicle;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
  },
}
</script>