<template>
  <!-- <div id="receipt" ref="receipt">
    <h1 style="text-align: center;">利佳利贸易</h1>
    <h1>Likali Trading 200103077382</h1><h3>(AS0230424-W)</h3>
    <p><strong>Customer Name:</strong> John Doe</p>
    <p><strong>Order ID:</strong> 12345</p>
    <p><strong>Date:</strong> {{ new Date().toLocaleDateString() }}</p>
    <table>
      <thead>
        <tr>
          <th>Item</th> 
          <th>Quantity</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.price }}</td>
        </tr>
      </tbody>
    </table>
    <p><strong>Total:</strong> ${{ total }}</p>
  </div> -->
<div class="body">
  <div class="receipt_container" ref="receipt_container">
    <div class="header">
      <h1>利佳利贸易*</h1>
      <p><b style="margin: 0;font-size:24px">LIKALI TRADING*</b> <span style="margin: 0;font-size:12px">4200103077382(AS0230424-W)*</span></p>
      <p>No.213, Padang Lembu, 08330 Gurun, Kedah Darul Aman.*</p>
      <p>H/P: 019-4455145 Email: acjnursery@gmail.com</p>
    </div>
    <div class="details">
      <div style="text-align: center;"><strong>INVOICE</strong></div><hr />
    </div>
    <div class="details">
      <table>
        <tr>
          <td style="width:60%">
            <div><strong>CHOO TIMBER TRADING</strong></div>
            <div style="width:80%">PT 3786, BANDAR UTAMA FASA 1, JALAN JATI 12A, 18300 GUA MUSANG, KELANTAN, MALAYSIA*</div>
          </td>
          <td>
            <div><strong>Invoice No.: </strong>12345*</div>
            <div><strong>Date: </strong>21-Nov-2024*</div>
            <div><strong>Sales Person: </strong>John Doe*</div>
            <div><strong>P/O No.: </strong>-</div>
            <div><strong>Pages: </strong>1</div>
            <div><strong>Term: </strong>CASH TERM*</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="details">
      <div><strong>Attn:</strong>THOMAS CHOO*</div>
      <div><strong>Tel:</strong>019-738 444/016248 9191*</div>
      <div><strong>Email:</strong>thomaschoo998@gmail.com*</div>
      <div><strong>Remark:</strong></div>
    </div>
    <table class="items">
      <thead>
        <tr>
          <th style="width:10%">No.</th>
          <th style="width:40%">Description</th>
          <th style="width:15%">Quantity</th>
          <th style="width:15%">Unit Price (RM)</th>
          <th style="width:20%">Amount (RM)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Membekal Anak Benih Durian klon musang king</td>
          <td>500PK</td>
          <td>23.00</td>
          <td>1,500.00</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Membekal Anak Benih Durian klon musang king</td>
          <td>500PK</td>
          <td>23.00</td>
          <td>1,500.00</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Membekal Anak Benih Durian klon musang king</td>
          <td>500PK</td>
          <td>23.00</td>
          <td>150,000.00</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">SATU RATUS TUJUH PULUH RIBU SAHAJA</td>
          <td>Total:</td>
          <td><STRONG>170,000.00</STRONG></td>
        </tr>
      </tfoot>
    </table>
    <!-- <div class="totals">
      <div><strong>Total: </strong>800PK</div>
    </div> -->
    <hr />
    <div class="details" style="font-size: 10px;">
      <div>Note:</div>
      <div>1. All cheques should be crossed and make payable to <strong>LIKALI TRADING*</strong></div>
      <div>BANK DETAILS:-</div>
      <div><strong>PUBLIC BANK: 380....</strong></div>
      <div>Goods sold are neither returnable or refundable. Otherwise a cancellation fee of 20% on purchased price will be imposed.</div>
    </div>
    <div class="details">
      <table style="width: 100%;font-size:10px">
        <tr>
          <td>
            <div style="width:35%">
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <hr />
              <div><strong>Authorised Signature</strong></div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="footer">
      <p>Thank you for your business!</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
data() {
  return {
    items: [
      { no:"1",subtotal:60, description: "Product A", quantity: 2, price: 30 },
      { no:"2",subtotal:20, description: "Product B", quantity: 1, price: 20 },
    ],
  };
},
props: {
  ordersno:{ type: String, required: true }
},
computed: {
  total() {
    return this.items.reduce((sum, item) => sum + item.quantity * item.price, 0);
  },
},
};
</script>
<!--   
<style scoped>
#receipt {
width: 210mm; /* A4 width */
min-height: 297mm; /* A4 height */
padding: 20mm;
font-family: Arial, sans-serif;
border: 1px solid #ddd; /* Optional for better preview */
background-color: white;
}

table {
width: 100%;
border-collapse: collapse;
}

table th, table td {
border: 1px solid #ddd;
padding: 8px;
text-align: left;
}
</style> -->
<style scoped>
.body {
  color:black;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}
.receipt_container {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  border: 1px solid #ccc;
  margin: auto;
  box-sizing: border-box;
  background: #fff;
}
.header {
  text-align: center;
  margin-bottom: 30px;
}
.header h1 {
  margin: 0;
  font-size: 20px;
  color:#000;
}
.header p {
  margin: 5px 0;
}
.details {
  margin-bottom: 30px;
  font-size: 15px;
  font-size: 13px;
}
.details div {
  margin-bottom: 5px;
}
.items {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}
.items th, .items td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
  font-size: 13px;
}
.items th {
  background-color: #f0f0f0;
}
.totals {
  text-align: right;
}
.totals div {
  margin-bottom: 5px;
}
.footer {
  text-align: center;
  margin-top: 30px;
  font-size: 12px;
}
</style>