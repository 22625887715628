<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import CustomerInfoTable from "./Tables/CustomerInfoTable.vue";
// import ArgonButton from "@/components/ArgonButton.vue";

</script>
<template>
    <div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Customer Details</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label"></label>
                            <input class="form-control" v-model="model.customer.name" id="input_name" type="text" placeholder="Ang Wei Ze" />
                            
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Company</label>
                            <input class="form-control" v-model="model.customer.company" id="input_company" type="text" placeholder="ACJ Nursery Sdn Bhd" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Contact</label>
                            <input class="form-control" v-model="model.customer.contact" id="input_contact" type="text" placeholder="0168815145" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Status</label>
                            <select class="form-control" v-model="model.customer.status" id="select_status" >
                                <option value="0">Open</option>
                                <option value="1">Following</option>
                                <option value="5">Pending</option>
                                <option value="2">Success</option>
                                <option value="3">Failed</option>
                                <option value="4">Resechedule</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">Address</label>
                            <argon-input type="text" v-model="model.customer.address" id="input_address" placeholder="213 Padng Lembu, 08330 Gurun Kedah" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">State</label>
                            <argon-input type="text" v-model="model.customer.state" id="input_state" placeholder="Kedah" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" v-model="model.customer.remark" id="input_remark" placeholder="Remark...." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success "  @click="updateCustomer" style="float: right;" id="btn_update">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Add Customer Message</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        
                        <div class="col-md-9">
                            <!-- <label for="example-text-input" class="form-control-label">Message</label> -->
                            <argon-input type="text" v-model="model.customerInfo.message" id="input_message" placeholder="Message..." />
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-success " @click="addCustomerInfo" style="float: right;" id="btn_addMsg">Add Message</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 row">
        <div class="col-12">
            <CustomerInfoTable :key="componentKey"/>
        </div>
    </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
  name:'customerCreate',
  data(){
    return {
        componentKey: 0,
    //   errorList: '',
      model:{
        customer:{
            customerid:'',
            name:'',
            address:'',
            contact:'',
            remark:'',
            company:'',
            status:'',
            state:''
        },
        customerInfo:{
            customerid:'',
            message:''
        }
        

      }
    }
  },
  mounted(){
    this.model.customerInfo.customerid = this.$route.params.id;
    this.model.customer.customerid = this.$route.params.id;
    this.getCustomerDetailByid();
  },
  methods: {
    getCustomerDetailByid(){
        axios.get('customer/getCustomerDetailByID/' + this.$route.params.id)
            .then(res => {
                this.model.customer = res.data.customer[0];
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    updateCustomer(){
        // var mythis = this;
        axios.post('customer/update',this.model.customer)
            .then(res => {
                alert(res.data.message);
                // this.model.customer = {
                //     customerid:'',
                //     name:'',
                //     address:'',
                //     contact:'',
                //     remark:'',
                //     company:'',
                //     state:''
                // };
                this.componentKey += 1;
                this.getCustomerDetailByid();
            }).catch(function (error){
                // if(error.response){
                //     if(error.response.status == 422){
                //         alert('Error: Name, Company, Contact, State and Address is required! ');
                //     }
                // }else if(error.request){
                //     console.log(error.request);
                // }else{
                    console.log('Error',error.message);
                // }
            });
    },
    addCustomerInfo(){
        axios.post('customer/insertCustomerInfo',this.model.customerInfo)
            .then(res => {
                alert(res.data.message);
                this.componentKey += 1;
            }).catch(function (error){
                    console.log('Error',error.message);
            });
    }
  },
}
</script>