<script setup>
// import { ref } from 'vue';
import SewaTable from "./Tables/SewaTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { onMounted } from "vue";
import { Modal } from 'bootstrap';
// const modalActive = ref(false);
// const toggleModal = () =>{
//     modalActive.value = !modalActive.value;
// };

// import ArgonButton from "@/components/ArgonButton.vue";
let myModal;
onMounted(() => {
    myModal = new Modal(document.getElementById('sewaProfileModal'));
});
const showPopupSewaProfile = () =>{
    myModal.show();
};

</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Sewa</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Address</label>
                            <select class="form-control" v-model="model.sewa.sewaprofileid" @change="showSewaAmountByID">
                                <option v-for="(sewaProfile,index) in model.sewaProfile" :key="index" :value="sewaProfile.id">
                                    {{ sewaProfile.id }}. {{ sewaProfile.address }} - {{ sewaProfile.remark }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Amount</label>
                            <input class="form-control" v-model="model.sewa.amount" id="input_amount" type="number" placeholder="80.00" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Date</label>
                            <input type="date" id="input_date" v-model="model.sewa.paymentdate" class="form-control">
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Reference No</label>
                            <argon-input type="text" id="input_reference" v-model="model.sewa.reference"  placeholder="Reference No...." />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" id="input_remark" v-model="model.sewa.remark"  placeholder="Remark...." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-primary " @click="showPopupSewaProfile" id="btn_add">Add Sewa Profile</button>
                            <button class="btn btn-success " type="button" @click="addSewa" style="float: right;" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <SewaTable :key="componentKey" />
        </div>
    </div>
</div>

<div class="row">
    <sewaProfileModal @updateSewaProfiledll="getSewaProfile">
    </sewaProfileModal>
</div>
</template>


<script>
import axios from 'axios';
import sewaProfileModal from "./components/NewSewaProfile.vue";
export default {
  name:'AddSewaPajak',
  components:{
    sewaProfileModal,
  },
  data(){
    return {
        componentKey: 0,
    //   errorList: '',
      model:{
        sewa:{
            sewaprofileid:'',
            reference:'',
            remark:'',
            paymentdate: new Date().toISOString().slice(0,10),//
            amount:'0'
        },
        sewaProfile:[]

      }
    }
  },
  mounted(){
    this.getSewaProfile();
  },
  methods: {
    addSewa(){
        // var mythis = this;
        // console.log(this.model.sewaPajak);
        axios.post('sewa/addSewa',this.model.sewa)
            .then(res => {
                alert(res.data.message);
                this.model.sewa = {
                    sewaprofileid:'',
                    reference:'',
                    remark:'',
                    paymentdate: new Date().toISOString().slice(0,10),
                    amount:'0'
                };
                this.componentKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Address, Date, and Amount is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    showSewaAmountByID(){
        axios.get('sewa/getSewaProfileByID/' + this.model.sewa.sewaprofileid)
            .then(res => {
                this.model.sewa.amount = res.data.sewaProfileDetail[0].amount;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error!');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    getSewaProfile(){
        // var mythis = this;
        axios.get('sewa/listAllSewaProfile')
            .then(res => {
                this.model.sewaProfile = res.data.sewaProfile;
                this.model.sewa.sewaprofileid = res.data.sewaProfile[0].id;
                this.model.sewa.amount = res.data.sewaProfile[0].amount;
                // console.log(res.data.sewaProfile[0]);
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>