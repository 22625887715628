<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Lorry Services table</h6>
        <div class="row">
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date From</label>
                <input type="date" id="input_date" class="form-control" v-model="search.datefrom">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date To</label>
                <input type="date" id="input_date" class="form-control" v-model="search.dateto">
            </div>
            <div class="col-md-2">
              <label for="example-text-input" class="form-control-label">Services Type</label>
              <select class="form-control" v-model="search.type">
                  <option value="0">
                      All
                  </option>
                  <option v-for="(lorryservicestype,index) in this.lorryservicestype" :key="index" :value="lorryservicestype.id">
                      {{ lorryservicestype.service }}
                  </option>
              </select>
            </div>
            <div class="col-md-2">
                <label for="example-text-input" class="form-control-label">Vehicle No</label>
                <input class="form-control" v-model="search.vehicleno" id="input_vehicleno" type="text" placeholder="KCB8028" />
            </div>
            <div class="col-md-2">
                <label for="example-text-input" class="form-control-label" style="width: 100%;">&nbsp;</label>
                <button class="btn btn-success " style="float:right;" id="btn_search">Search</button>
            </div>
        </div>
      </div>
      <hr />
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Lorry
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Service
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Location
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Remarks
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Service Date
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  
                </th>
                <!-- <th class="text-secondary opacity-7"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lorryservices,index) in this.lorryservices" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ lorryservices.id }} - {{ lorryservices.vehicleno }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ lorryservices.lorryservicestype }}<br />
                    RM {{ lorryservices.amount }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ lorryservices.location }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ lorryservices.reference }}<br />
                    {{ lorryservices.remark }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ lorryservices.servicedate }}<br />
                    Meter: {{ lorryservices.servicemeter }}
                    </span>
                </td>
                <td class="align-middle">
                  <button class="btn btn-danger font-weight-bold text-xs" @click="removeLorryService(lorryservices.id)">Remove</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
<script>
import axios from 'axios';

export default {
  name:'lorryServicesSelect',
  data(){
    return {
        lorryservices: [],
        lorryservicestype:[],
        removeLorrySevices: {
          id:''
        },
        search:{
          dateto: new Date().toISOString().slice(0,10),
          datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
          type: '',
          vehicleno: '',
        }
    }
  },
  mounted(){
    this.getLorryServices();
    this.getLorryServicesType();
  },
  methods: {
    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    getLorryServices(){
        var vm = this;
        var servicetype = vm.search.type;
        var vehicleno = vm.search.vehicleno;
        if(vehicleno == '')vehicleno = 'all';
        if(servicetype == '')servicetype = '0';
        axios.get('vehicle/listAllServices/' + vm.search.datefrom + '/' + 
        vm.search.dateto + '/' + servicetype + '/' + vehicleno)
            .then(res => {
                this.lorryservices = res.data.lorryservices;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    getLorryServicesType(){
        // var mythis = this;
        axios.get('vehicle/listservicestype')
            .then(res => {
                this.lorryservicestype = res.data.lorryservicestype;
                this.search.type = '0';
                // console.log(this.model.lorryservicestype);
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    removeLorryService(id){
      if(confirm("Do you really want to remove this service records? ID - " + id)){
        this.removeLorrySevices.id = id;
        axios.post('vehicle/removeLorrySevices',this.removeLorrySevices)
            .then(res => {
                alert(res.data.message);
                this.removeLorrySevicesID = '';
                this.getLorryServices();
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: ID is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
      }
    }
  },
}
</script>