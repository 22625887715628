<script setup>
// import ArgonInput from "@/components/ArgonInput.vue";
import UsersTable from "./Tables/UsersTable.vue";

</script>
<template>
    <div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Users Info</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Name</label>
                            <input class="form-control" v-model="model.user.name" id="input_name" type="text" placeholder="Name..." />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">User Name</label>
                            <input class="form-control" v-model="model.user.username" id="input_password" type="text" placeholder="Username..." />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">password</label>
                            <input class="form-control" v-model="model.user.password" id="input_password" type="password" placeholder="****" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " style="float: right;" @click="addUser" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <UsersTable :key="tableKey" />
        </div>
    </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
  name:'signupUser',
  data(){
    return {
      tableKey: 0,
      model:{
        user:{
            username:'',
            name:'',
            password:''
        },
        

      }
    }
  },
  methods: {
    addUser: async  function(){
      await axios.post('user/add',this.model.user)
            .then(res => {
                alert(res.data.message);
                this.model.user = {
                  username:'',
                  name:'',
                  password:''
                };
                this.tableKey += 1;
            }).catch((error) =>{
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Username and Password is required! ');
                    }
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>