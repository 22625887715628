import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
// import Billing from "../views/Billing.vue";
// import Profile from "../views/Profile.vue";
import Signin from "../views/Signin.vue";
// import Signup from "../views/Signup.vue";
import Customer from "../views/Customer.vue";
import EditCustomer from "../views/EditCustomer.vue";
import EditBlock from "../views/EditBlock.vue";
import Block from "../views/Block.vue";
import Users from "../views/Users.vue";
import SawitBuah from "../views/SawitBuah.vue";
import SewaPajak from "../views/SewaPajak.vue";
import Baja from "../views/Baja.vue";
import LorryServices from "../views/LorryServices.vue";
import LorryCostKM from "../views/LorryCostKM.vue";
import Vehicle from "../views/Vehicle.vue";
import Sewa from "../views/Sewa.vue";
import Blockexpenses from "../views/BlockExpenses.vue";
import BlockPNL from "../views/BlockPNL.vue";
import NotFound404 from "../views/NotFound404.vue";
import Product from "../views/Product.vue";
import Order from "../views/Order.vue";
import DeliveryList from "../views/DeliveryList.vue";
import OrderList from "../views/OrderList.vue";
import EditOrder from "../views/EditOrder.vue";
// import axios from 'axios';

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard-default",
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
  },
  {
      path: "/editCustomer/:id",
      name: "EditCustomer",
      component: EditCustomer,
  },
  {
      path: "/editBlock/:id",
      name: "EditBlock",
      component: EditBlock,
  },
  {
    path: "/block",
    name: "Block",
    component: Block,
  },
  {
    path: "/sawitbuah",
    name: "SawitBuah",
    component: SawitBuah,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/sewapajak",
    name: "SewaPajak",
    component: SewaPajak,
  },
  {
    path: "/baja",
    name: "Baja",
    component: Baja,
  },
  {
    path: "/lorryservices",
    name: "LorryServices",
    component: LorryServices,
  },
  {
    path: "/lorrycostkm",
    name: "LorryCostKM",
    component: LorryCostKM,
  },
  {
    path: "/vehicle",
    name: "Vehicle",
    component: Vehicle,
  },
  {
    path: "/sewa",
    name: "Sewa",
    component: Sewa,
  },
  {
    path: "/blockexpenses",
    name: "Blockexpenses",
    component: Blockexpenses,
  },
  {
    path: "/blockpnl",
    name: "BlockPNL",
    component: BlockPNL,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
  },
  {
    path: "/deliverylist",
    name: "DeliveryList",
    component: DeliveryList,
  },
  {
    path: "/orderlist",
    name: "OrderList",
    component: OrderList,
  },
  {
      path: "/editOrder/:ordersno",
      name: "EditOrder",
      component: EditOrder,
  },
  {
    path:"/:catchAll(.*)",
    component: NotFound404,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior() {     
    return { top: 0, left: 0 } 
  },
});

// const loginUser = () => {
//   // var mythis = this;
  
//   axios.get('user/user')
//       .then(res => {
//         return res.data;
//       }).catch(function (error){
//           if(error.message == 'Unauthenticated.'){
//             alert('User Unauthenticated.Please login! ');
//           }else{
//               console.log('Error',error.message);
//           }
//       });
// };

router.beforeEach((to, from, next) => {
  // let isAuthenticated = false;
  // if ( ){
  //   isAuthenticated = true;
  // }
  // console.log(localStorage.getItem('token').length);
  // if (localStorage.getItem('token'))
  if ((to.name !== 'Signin' ) && !localStorage.getItem('token')) next({ name: 'Signin' })
  else next()
});

export default router;
