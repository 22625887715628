<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Block table</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Block
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Address
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Details
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Payment
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Year
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(block,index) in this.block" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ block.blockid }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ block.blockname}}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ block.address }}</h6>
                  <p class="text-xs text-secondary mb-0">{{ block.remark }}</p>
                  <p class="text-xs text-secondary mb-0">{{ block.plant_year }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ block.Relong }}</p>
                  <p class="text-xs text-secondary mb-0">{{ block.quantity }} PK</p>
                  <p class="text-xs text-secondary mb-0">{{ block.est_kg }} KG</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ block.term }}</p>
                  <p class="text-xs text-secondary mb-0">RM {{ block.amount }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ block.plant_date }}</p>
                  <p class="text-xs text-secondary mb-0">RM {{ block.plant_year }}</p>
                </td>
                <td class="align-middle">
                  <RouterLink :to="{path : '/editBlock/'+ block.blockid}"
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit Block"
                    >Edit
                  </RouterLink>
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>

  
<script>
import axios from 'axios';

export default {
  name:'blockSelect',
  data(){
    return {
      block: []
    }
  },
  mounted(){
    this.getBlockList();
  },
  methods: {

    getBlockList(){
        axios.get('block/listAllDetails')
            .then(res => {
              console.log(res.data.block);
              this.block = res.data.block;
            }).catch(function (error){
                
              console.log('Error',error.message);
            });
    }
  },
}
</script>
  