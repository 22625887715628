<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Users table</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Username
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Date
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(users,index) in this.users" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ users.name }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold" >
                    {{ users.username }}
                    </span>
                </td>
                
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold" >
                    {{ users.created_at }}
                    </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
<script>
import axios from 'axios';

export default {
  name:'usersSelect',
  data(){
    return {
      users: []
    }
  },
  mounted(){
    this.getusers();
  },
  methods: {

    getusers(){
        // var mythis = this;
        axios.get('user/listAll')
            .then(res => {
                this.users = res.data.users;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
  },
}
</script>