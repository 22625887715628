<script setup>
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";

</script>
<template>
    <div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Orders Details</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-12">
                            <h3>{{ model.orders.companyprofile }} - Orders No: {{ model.orders.ordersno }}</h3>
                        </div>
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">Customer:</label>
                            <h6 class="font-weight-bold mb-0 text-xs " style="white-space: normal;word-wrap: break-word;word-break: break-word;">
                                {{ model.orders.name }}<br />
                                {{ model.orders.company}}<br />
                                {{ model.orders.ic}}<br />
                                {{ model.orders.contact }}<br />
                                {{ model.orders.address }}
                            </h6>
                        </div>
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">Prices:</label>
                            <h6 class="font-weight-bold mb-0 text-xs " style="white-space: normal;word-wrap: break-word;word-break: break-word;">
                                Delivery No: {{ model.orders.deliveryno }}<br />
                                RM {{ model.orders.price }}<br />
                                RM {{ model.orders.tax}}<br />
                                <h5>Total: RM <b>{{ model.orders.total}}</b></h5>
                            </h6>
                        </div>
                    </div> 
                    <hr /> 
                    <div class="row">
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Order Status</label>
                            <select class="form-control" v-model="model.orders.orderstatus" id="select_status" >
                                <option value="0">Pending</option>
                                <option value="1">Half-Payment</option>
                                <option value="2">Completed</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Delivery Status</label>
                            <select class="form-control" v-model="model.orders.deliverystatus" id="select_status" >
                                <option value="0">Pending</option>
                                <option value="1">Delivering</option>
                                <option value="2">Completed</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success " style="margin-top: 30px;"  @click="updateOrder" id="btn_update">Update</button>
                            <button class="btn btn-info " style="margin-top: 30px;margin-left: 10px" @click="printOrder" id="btn_print">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Order Details</h6>
                </div>
                <hr />
                <div class="container-fluid">
                    <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-xs font-weight-bolder">
                                    Product
                                    </th>
                                    <th class="text-uppercase text-xs font-weight-bolder">
                                    Price
                                    </th>
                                    <th class="text-uppercase text-xs font-weight-bolder">
                                    Quantity
                                    </th>
                                    <th class="text-uppercase text-xs font-weight-bolder">
                                    Sub Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in this.model.orderdetails" :key="index">
                                    <td>
                                        <div class="d-flex px-2 py-1">
                                            <div class="d-flex flex-column justify-content-center">
                                            <h5 class="mb-0 ">{{ item.productname }}</h5>
                                            <h5 class=" text-secondary mb-0">{{ item.detail }}</h5>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold" >
                                        <h5 class="font-weight-bold mb-0">RM {{ item.unitprice }}</h5>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold" >
                                        <h5 class="font-weight-bold mb-0">{{ item.quantity }} pk/beg</h5>
                                        </span>
                                    </td>
                                    <td>
                                        <h4 class=" font-weight-bold" >
                                        RM {{ item.subtotal }}
                                        </h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-md-6">
                            <h4 style="text-align: right">Total: </h4>
                        </div>
                        <div class="col-md-6">
                            <h4 style="text-align: right"><b class="text-xl">RM {{ model.orders.total }} (Tax: RM {{model.orders.tax}})</b></h4>          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'customerCreate',
  data(){
    return {
    //   errorList: '',
      model:{
        orders:{
            ordersid:'',
            ordersno:'',
            companyprofile:'',
            name:'',
            company:'',
            address:'',
            contact:'',
            ic:'',
            price:'',
            tax:'',
            total:'',
            deliverystatus:'',
            orderstatus:'',
            dateorder:'',
            deliveryno:''
        },
        orderdetails:{
            ordersno:'',
            productsid:'',
            quantity:'',
            unitprice:'',
            detail:'',
            subtotal:'',
            productname:''
        }
        

      }
    }
  },
  mounted(){
    this.model.orderdetails.ordersno = this.$route.params.ordersno;
    this.model.orders.ordersno = this.$route.params.ordersno;
    this.getOrderDetailByOrdersno();
  },
  methods: {
    getOrderDetailByOrdersno(){
        axios.get('order/listOrderDetailByOrdersno/' + this.$route.params.ordersno)
            .then(res => {
                this.model.orders = res.data.orders;
                this.model.orderdetails = res.data.details;
                console.log(this.model.orderdetails );
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    updateOrder(){
        // var mythis = this;
        axios.post('order/updateOrderStatus',this.model.orders)
            .then(res => {
                alert(res.data.message);
                // this.getCustomerDetailByid();
            }).catch(function (error){
                console.log('Error',error.message);
            });
    },
    addCustomerInfo(){
        axios.post('customer/insertCustomerInfo',this.model.customerInfo)
            .then(res => {
                alert(res.data.message);
                this.componentKey += 1;
            }).catch(function (error){
                    console.log('Error',error.message);
            });
    }
  },
}
</script>