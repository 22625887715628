<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import CustomerTable from "./Tables/CustomerTable.vue";
// import ArgonButton from "@/components/ArgonButton.vue";

</script>
<template>
    <div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Customer Info</h6>
                </div>
                <div class="py-4 container-fluid">
                    <!-- <div class="row">
                        <ul v-if="Object.keys(this.errorList).length>0">
                            <li class="form-control-label" v-for="(error,index) in this.errorList" :key="index">
                                {{ error[0] }}
                            </li>
                        </ul>
                    </div> -->
                    <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Name</label>
                            <input class="form-control" v-model="model.customer.name" id="input_name" type="text" placeholder="Ang Wei Ze" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Company</label>
                            <input class="form-control" v-model="model.customer.company" id="input_company" type="text" placeholder="ACJ Nursery Sdn Bhd" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Contact</label>
                            <input class="form-control" v-model="model.customer.contact" id="input_contact" type="text" placeholder="0168815145" />
                        </div>
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">Address</label>
                            <argon-input type="text" v-model="model.customer.address" id="input_address" placeholder="213 Padng Lembu, 08330 Gurun Kedah" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">State</label>
                            <argon-input type="text" v-model="model.customer.state" id="input_state" placeholder="Kedah" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" v-model="model.customer.remark" id="input_remark" placeholder="Remark...." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success "  @click="addCustomer" style="float: right;" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <CustomerTable :key="componentKey"/>
        </div>
    </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
  name:'customerCreate',
  data(){
    return {
        componentKey: 0,
    //   errorList: '',
      model:{
        customer:{
            name:'',
            address:'',
            contact:'',
            remark:'',
            company:'',
            state:''
        }
        

      }
    }
  },
  methods: {

    addCustomer(){
        // var mythis = this;
        axios.post('customer/add',this.model.customer)
            .then(res => {
                alert(res.data.message);
                this.model.customer = {
                    name:'',
                    address:'',
                    contact:'',
                    remark:'',
                    company:'',
                    state:''
                };
                this.componentKey += 1;
                
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
  },
}
</script>