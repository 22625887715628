<script setup>
// import { ref } from 'vue';
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<style scoped>
.wrap-text {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}
</style>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Delivery List</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">Quick Search</label>
                            <input id="input_quicksearch" v-model="quicksearch" class="form-control" placeholder="Customer Name, Company, Address, Contact...."/>
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Status</label>
                            <select class="form-control" v-model="quicksearchstatus">
                                <option value="all">All</option>
                                <option value="0">Pending</option>
                                <option value="1">Half Payment</option>
                                <option value="2">Completed</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                        </div>
                        <div class="col-md-1">
                            <label for="example-text-input" class="form-control-label">&nbsp;</label>
                            <button class="btn btn-success " @click="getDeliveryListByQuickSearch" style="float: right;margin: 5px;" id="btn_search">Search</button>
                            
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Order/Delivery No</label>
                            <input id="input_order" v-model="search.ordersno" class="form-control"/>
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Customer Name</label>
                            <input id="input_customer" v-model="search.customer" class="form-control"/>
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Company Profile</label>
                            <select class="form-control" v-model="search.companyprofile">
                                <option value="all">All</option>
                                <option value="1">Ang Chin Joo Nursery SDN BHD</option>
                                <option value="2">Ang Chin Joo Rubber & Palm Oil</option>
                                <option value="3">Others</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Status</label>
                            <select class="form-control" v-model="search.status">
                                <option value="all">All</option>
                                <option value="0">Pending</option>
                                <option value="1">Half Payment</option>
                                <option value="2">Completed</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Date From</label>
                            <input type="date" id="input_date" v-model="search.datefrom" class="form-control" >
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Date To</label>
                            <input type="date" id="input_date" v-model="search.dateto" class="form-control" >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="getOrderList" style="float: right;margin: 5px;" id="btn_search">Search</button>
                            <button class="btn btn-primary " @click="exportPnL" style="float: right;margin: 5px;" id="btn_search">Export</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                    <h6>Delivery History</h6>
                </div>
                <div class="card-body px-0 pt-0 pb-2">
                    <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                        <thead>
                        <tr>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Company
                            </th>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Customer
                            </th>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Details
                            </th>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Status
                            </th>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Date
                            </th>
                            <th class="text-secondary opacity-7"></th>
                        </tr>
                        </thead>
                        <tbody>
                        
                            <tr v-for="(item,index) in this.deliverylist" :key="index">
                            <td style="width: 15%;" class="wrap-text">
                            <div class="d-flex px-2 py-1">
                                <div class="justify-content-center" >
                                    <h5 class="text-sm">{{ item.companyname }}</h5>
                                </div>
                            </div>
                            </td>
                            <td style="width: 20%;" class="wrap-text">
                            <h6 class="font-weight-bold mb-0 text-xs " style="white-space: normal;word-wrap: break-word;word-break: break-word;">
                                {{ item.name }}<br />
                                {{ item.company }}<br />
                                {{ item.address }}</h6>
                            </td>
                            <td style="width: 20%;" class="wrap-text">
                                <p class="text-xs font-weight-bold" >
                                    Contact: {{ item.contact }}<br />
                                    <b>Order No:{{ item.ordersno }}</b><br />
                                    Total: RM{{ item.total }}
                                </p>
                            </td>
                            <td style="width: 20%;" class="wrap-text">
                                <span v-if="item.orderstatus == '2'" class="badge badge-sm bg-gradient-success text-xs">Order Completed</span>
                                <span v-else-if="item.orderstatus == '0'" class="badge badge-sm bg-gradient-danger text-xs">Order Pending</span>
                                <span v-else class="badge badge-sm bg-gradient-info text-xs">Order Half-Payment</span><br />
                                <span v-if="item.deliverystatus == '2'" class="badge badge-sm bg-gradient-success text-xs">DO Completed</span>
                                <span v-else-if="item.deliverystatus == '0'" class="badge badge-sm bg-gradient-danger text-xs">DO Pending</span>
                                <span v-else class="badge badge-sm bg-gradient-info text-xs">DO Delivering</span>
                            </td>
                            <td style="width: 12%;" class="wrap-text">
                            <p class="font-weight-bold mb-0 text-xs">{{ item.dateorder }}</p>
                            </td>
                            <td class="align-middle wrap-text" style="width: 13%;" >
                            <RouterLink :to="{path : '/editOrder/'+ item.ordersno}"
                                href="javascript:;"
                                class="font-weight-bold text-xs btn btn-info"
                                data-toggle="tooltip"
                                data-original-title="Edit Order"
                                >Edit
                            </RouterLink><br />
                            <button class="btn btn-success text-xs" @click="generatePDF">Print Invoice</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <DeliveryReceipt ref="deliveryReceipt" />
    </div>
</div>
</template>


<script>
import axios from 'axios';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import DeliveryReceipt from "./components/DeliveryReceipt.vue";

export default {
  components: {
    DeliveryReceipt,
  },
  name:'blockCreate',
  data(){
    return {
        componentKey: 0,
        search:{
            datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
            dateto: new Date().toISOString().slice(0,10),
            status:'all',
            customer:'',
            companyprofile: 'all',
            ordersno:'',
        },
        quicksearch:'',
        deliverylist:[],
        quicksearchstatus: 'all'
    }
  },
  mounted(){
    this.getDeliveryList();
  },
  methods: {
    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    async generatePDF() {
      const receiptElement = this.$refs.deliveryReceipt.$refs.receipt;

      // Convert the receipt to a canvas
      const canvas = await html2canvas(receiptElement);

      // Convert the canvas to an image
      const imgData = canvas.toDataURL("image/png");

      // Create a jsPDF instance
      const pdf = new jsPDF();

      // Add the image to the PDF
      pdf.addImage(imgData, "PNG", 10, 10, 180, 0);

      // Download the PDF
      pdf.save("receipt.pdf");
    },
    addBlock(){
        // var mythis = this;
        axios.post('block/add',this.model.block)
            .then(res => {
                alert(res.data.message);
                this.model.block = {
                    blockname:'',
                    address:'',
                    remark:'',
                    relong:'',
                    quantity:'',
                    est_kg:'',
                    plant_date:'',
                    blockid: '',
                    term:'',
                    amount:'',
                    bank:'',
                    bankacc:'',
                    name:''
                };
                this.componentKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block Name, ID, Relong, Address, Payment Term and Amount is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    getDeliveryList(){
        
        let customername = 'all';
        if(this.search.customer != ''){
            customername = this.search.customer;
        }
        axios.get('delivery/listDelivery/'+ this.search.datefrom + "/" + this.search.dateto + "/" + this.search.status + "/"+ customername + "/" + this.search.companyprofile + "/" + this.search.ordersno)
            .then(res => {
                this.deliverylist = res.data.deliverylist;
                // console.log(this.deliverylist);
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    getDeliveryListByQuickSearch(){
        
        let v_quicksearch = 'all';
        if(this.quicksearch != ''){
            v_quicksearch = this.quicksearch;
        }
        axios.get('delivery/listDeliverylistByQuickSearch/'+ v_quicksearch + '/'  +this.quicksearchstatus)
            .then(res => {
                this.deliverylist = res.data.deliverylist;
                
                // console.log(res.data.deliverylist);
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    exportPnL(){
        if((this.blockPnL).length<0 || (this.blockPnL == '') ){
            alert("Please search the block pls. ");
        }else{
            let fileName = 'BlockAccount_' + this.search.blockid + '_'+ this.search.datefrom + '_' + this.search.dateto ;
            const csvContent = this.convertToCSV(this.blockPnL);
            const blob = new Blob([csvContent],{type:'text/csv;charset=utf-8'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',fileName+'.csv');
            link.click();
        }
    },
    convertToCSV(data){
        const headers = Object.keys(data[0]);
        const rows = data.map(obj => headers.map(header => obj[header]));
        const headerRow = headers.join(',');
        const csvRows = [headerRow, ...rows.map(row => row.join(','))];

        return csvRows.join('\n');

    }
    
  },
}
</script>