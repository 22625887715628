<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Baja Table</h6>
        <div class="row">
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date From</label>
                <input type="date" id="input_date" class="form-control" v-model="search.datefrom">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date To</label>
                <input type="date" id="input_date" class="form-control" v-model="search.dateto">
            </div>
            <div class="col-md-2">
                <label for="example-text-input" class="form-control-label">Block ID</label>
                <input class="form-control" v-model="search.blockid" id="input_blockid" type="text" placeholderK="A01" />
            </div>
            <div class="col-md-2">
                <label for="example-text-input" class="form-control-label" style="width: 100%;">&nbsp;</label>
                <button class="btn btn-success " style="float:right;" id="btn_search" @click="getAllBajaList">Search</button>
            </div>
        </div>
      </div>
      <hr />
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Blockid
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Baja
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Quantity
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Remarks
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Date
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  
                </th>
                <!-- <th class="text-secondary opacity-7"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(baja,index) in this.baja" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ baja.blockid }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ baja.type }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ baja.quantity }} Beg | {{ baja.weight }} KG
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ baja.remark }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ baja.date }}
                    </span>
                </td>
                <td class="align-middle">
                  <button class="btn btn-danger font-weight-bold text-xs" @click="removeLorryService(baja.id)">Remove</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
<script>
import axios from 'axios';

export default {
  name:'bajaSelect',
  data(){
    return {
        baja: [],
        removeLorrySevices: {
          id:''
        },
        search:{
          dateto: new Date().toISOString().slice(0,10),
          datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
          blockid: '',
        }
    }
  },
  mounted(){
    this.getAllBajaList();
  },
  methods: {
    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    getAllBajaList(){
        var blockid = '';
        blockid = this.search.blockid ;
        if(blockid == ""){
            blockid = 'all';
        }
        // var mythis = this;
        axios.get('baja/listBaja/' + this.search.datefrom + "/" + this.search.dateto + "/" + blockid)
            .then(res => {
                this.baja = res.data.baja;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    removeLorryService(id){
      if(confirm("Do you really want to remove this service records? ID - " + id)){
        this.removeLorrySevices.id = id;
        axios.post('vehicle/removeLorrySevices',this.removeLorrySevices)
            .then(res => {
                alert(res.data.message);
                this.removeLorrySevicesID = '';
                this.getLorryServices();
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: ID is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
      }
    }
  },
}
</script>