<script setup>
import { onMounted } from "vue";
import Chart from "chart.js/auto";
import axios from 'axios';

let dataLabel = [];
let dataValue = [];
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    default: "300",
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  chart: {
    type: Object,
    required: true,
    labels: Array,
    datasets: {
      type: Array,
      label: String,
      data: Array,
    },
  },
});


onMounted(() => {
  var gradientLineChart = document.getElementById(props.id).getContext("2d");

  var gradientStroke1 = gradientLineChart.createLinearGradient(0, 230, 0, 50);
  
  // gradientStroke1.addColorStop(0.5, "rgba(203,12,159,0.5)");
  // gradientStroke1.addColorStop(0.3, "rgba(203,12,159,0.3)");
  // gradientStroke1.addColorStop(0.1, "rgba(203,12,159,0.1)"); //purple colors
  
  // gradientStroke1.addColorStop(0.7, "rgba(75, 181, 67,0.7)");
  // gradientStroke1.addColorStop(0.4, "rgba(75, 181, 67,0.4)");
  // gradientStroke1.addColorStop(0.1, "rgba(75, 181, 67,0.1)"); //purple colors

  gradientStroke1.addColorStop(0.7, "rgba(81, 161, 237,0.7)");
  gradientStroke1.addColorStop(0.4, "rgba(81, 161, 237,0.4)");
  gradientStroke1.addColorStop(0.1, "rgba(81, 161, 237,0.1)"); //purple colors

  var gradientStroke2 = gradientLineChart.createLinearGradient(0, 230, 0, 50);

  gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
  gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
  gradientStroke2.addColorStop(0, "rgba(20,23,39,0)"); //purple colors

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }
  
 function setUp(){
  return new Promise((resolve, reject) => {
    axios.get('dashboard/getDashboadChartData')
            .then(res => {
            (res.data.dashboardChart).forEach((item)=>{
              // console.log(item.ttlWeight);
              dataValue.push(item.ttlWeight);
              dataLabel.push(item.monthname);
            });
            
            console.log(dataValue);
            resolve('Success');
            }).catch(function (error){
              reject(error.message)
                console.log('Error',error.message);
            });
          })          
  }
  setUp().then((response) =>{
    if(response == "Success"){
      new Chart(gradientLineChart, {
      type: "line",
      data: {
        labels: dataLabel,
        datasets: [
          {
            label: "Tonne",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#4BB543",
            backgroundColor: gradientStroke1,
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            fill: true,
            data: dataValue,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#fbfbfb",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#000000",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
    }
    
  
  });
  
});
</script>
<template>
  <div class="card z-index-2">
    <div class="pb-0 card-header mb-0">
      <h6>{{ props.title }}</h6>
      <!--  eslint-disable-next-line vue/no-v-html -->
        <!-- <p>{{ props.description.textChartIncrease }}</p> -->
      <p v-if="props.description.textChartIncrease" class="text-sm" v-html="props.description.textChartIncrease" />
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas
          :id="props.id"
          class="chart-canvas"
          :height="props.height"
        ></canvas>
      </div>
    </div>
  </div>
</template>
