<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Sewa Table</h6>
        <div class="row">
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date From</label>
                <input type="date" id="input_date" class="form-control" v-model="search.datefrom" >
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date To</label>
                <input type="date" id="input_date" class="form-control" v-model="search.dateto" >
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Address</label>
                <select class="form-control" v-model="search.sewaprofileid">
                    <option :value="0">All</option>
                    <option v-for="(sewaProfile,index) in sewaProfile" :key="index" :value="sewaProfile.id">
                        {{ sewaProfile.id }}. {{ sewaProfile.address }} - {{ sewaProfile.remark }}
                    </option>
                </select>
            </div>
            <div class="col-md-2">
            </div>
            <div class="col-md-1">
                <label for="example-text-input" class="form-control-label">&nbsp;</label>
                <button class="btn btn-success " @click="getSewaList" style="float: right;" id="btn_search">Search</button>
            </div>
        </div>
      </div>
      <hr />
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Address
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Amount
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Reference
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Remark
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Date
                </th>
                <!-- <th class="text-secondary opacity-7"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sewa,index) in this.sewa" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ sewa.address }}</h6>
                      <h6 class="mb-0 text-sm">{{ sewa.sewaprofileremark }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ sewa.amount }}</h6>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ sewa.reference }}</h6>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ sewa.remark }}</h6>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ sewa.paymentdate }}</h6>
                </td>
                <!-- <td class="align-middle">
                  <RouterLink :to="{path : '/editBlock/'+ sewaPajak.blockid}"
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit Block"
                    >Remove
                  </RouterLink>
                </td> -->
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>

  
<script>
import axios from 'axios';

export default {
  name:'sewaSelect',
  data(){
    return {
      sewa: [],
      sewaProfile:[],
      search:{
        sewaprofileid:'0',
        dateto: new Date().toISOString().slice(0,10),
        datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
      }
    }
  },
  mounted(){
    this.getSewaList();
    this.getSewaProfile();
  },
  methods: {
    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    getSewaProfile(){
        // var mythis = this;
        axios.get('sewa/listAllSewaProfile')
            .then(res => {
                console.log(res.data);
                this.sewaProfile = res.data.sewaProfile;
                this.search.sewaprofileid = '0';
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    getSewaList(){
        axios.get('sewa/listAllSewa/' + this.search.datefrom + '/' + this.search.dateto + '/' + this.search.sewaprofileid)
            .then(res => {
              // console.log(res.data.sewaPajak);
              this.sewa = res.data.sewa;
            }).catch(function (error){
                
              console.log('Error',error.message);
            });
    }
  },
}
</script>
  