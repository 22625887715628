<script setup>
// import { ref } from 'vue';
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Block Profit and Loss</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Block Id</label>
                            <select class="form-control" v-model="search.blockid">
                                <option v-for="(item,index) in blockidList" :key="index" :value="item.blockid">
                                    {{ item.blockid }} - {{ item.blockname }} - {{ item.remark }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Date From</label>
                            <input type="date" id="input_date" v-model="search.datefrom" class="form-control" >
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Date To</label>
                            <input type="date" id="input_date" v-model="search.dateto" class="form-control" >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="seachPnL" style="float: right;margin: 5px;" id="btn_search">Search</button>
                            <button class="btn btn-primary " @click="exportPnL" style="float: right;margin: 5px;" id="btn_search">Export</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                    <h6>Block Account (P&L)</h6>
                </div>
                <div class="card-body px-0 pt-0 pb-2">
                    <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                        <thead>
                        <tr>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Block
                            </th>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Remark
                            </th>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Debit
                            </th>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Credit
                            </th>
                            <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Date
                            </th>
                            <!-- <th class="text-secondary opacity-7"></th> -->
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,index) in this.blockPnL" :key="index">
                            <td>
                            <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">{{ item.blockid }}</h6>
                                </div>
                            </div>
                            </td>
                            <td>
                            <h6 class="text-xs font-weight-bold mb-0">{{ item.remark }}</h6>
                            </td>
                            <td>
                            <p class="text-xs font-weight-bold mb-0">RM {{ item.debit }}</p>
                            </td>
                            <td>
                            <p class="text-xs font-weight-bold mb-0">RM {{ item.credit }}</p>
                            <p class="text-xs font-weight-bold mb-0">{{ item.weight }} Tonne</p>
                            </td>
                            <td>
                            <p class="text-xs font-weight-bold mb-0">{{ item.date }}</p>
                            </td>
                            <!-- <td class="align-middle">
                            <RouterLink :to="{path : '/editBlock/'+ salesBuah.blockid}"
                                href="javascript:;"
                                class="text-secondary font-weight-bold text-xs"
                                data-toggle="tooltip"
                                data-original-title="Edit Block"
                                >Edit
                            </RouterLink>
                            </td> -->
                        </tr>
                        
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'blockCreate',
  data(){
    return {
        componentKey: 0,
        search:{
            datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
            dateto: new Date().toISOString().slice(0,10),
            blockid:''
        },
        blockidList:[],
        blockPnL:[],
    }
  },
  mounted(){
    this.getBlockList();
  },
  methods: {
    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    addBlock(){
        // var mythis = this;
        axios.post('block/add',this.model.block)
            .then(res => {
                alert(res.data.message);
                this.model.block = {
                    blockname:'',
                    address:'',
                    remark:'',
                    relong:'',
                    quantity:'',
                    est_kg:'',
                    plant_date:'',
                    blockid: '',
                    term:'',
                    amount:'',
                    bank:'',
                    bankacc:'',
                    name:''
                };
                this.componentKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block Name, ID, Relong, Address, Payment Term and Amount is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    getBlockList(){
        axios.get('block/getBlockidList')
            .then(res => {
                this.blockidList = res.data.blockidList;
                this.search.blockid = 'A01';
                // console.log(this.model.lorryservicestype);
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    seachPnL(){
        axios.get('block/listBlockPnL/'+ this.search.datefrom + "/" + this.search.dateto + "/" + this.search.blockid)
            .then(res => {
                this.blockPnL = res.data.blockpnl;
                
                console.log(res.data.blockpnl);
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    exportPnL(){
        if((this.blockPnL).length<0 || (this.blockPnL == '') ){
            alert("Please search the block pls. ");
        }else{
            let fileName = 'BlockAccount_' + this.search.blockid + '_'+ this.search.datefrom + '_' + this.search.dateto ;
            const csvContent = this.convertToCSV(this.blockPnL);
            const blob = new Blob([csvContent],{type:'text/csv;charset=utf-8'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',fileName+'.csv');
            link.click();
        }
    },
    convertToCSV(data){
        const headers = Object.keys(data[0]);
        const rows = data.map(obj => headers.map(header => obj[header]));
        const headerRow = headers.join(',');
        const csvRows = [headerRow, ...rows.map(row => row.join(','))];

        return csvRows.join('\n');

    }
    
  },
}
</script>