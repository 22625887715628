<script setup>
// import { ref } from 'vue';
import LorryCostKMTable from "./Tables/LorryCostKMTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Cost per KM</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Vehicle No</label>
                            <input class="form-control" v-model="model.costkm.vehicleno" id="input_vehicleno" type="text" placeholder="KCB8028" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Litre (L)</label>
                            <input class="form-control" v-model="model.costkm.litre" id="input_litre" type="number" placeholder="12.34" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Fuel Cost (RM)</label>
                            <input class="form-control" v-model="model.costkm.fuelcost" id="input_cost" type="number" placeholder="200.00" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Driver Cost (RM)</label>
                            <input class="form-control" v-model="model.costkm.drivercost" id="input_cost" type="number" placeholder="50.00" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Trip (Hours)</label>
                            <input class="form-control" v-model="model.costkm.triphours" id="input_cost" type="number" placeholder="1.0" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Start Odo Meter</label>
                            <input class="form-control" v-model="model.costkm.startmeter" id="input_startmeter" type="number" placeholder="100100" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">End Odo Meter</label>
                            <input class="form-control" v-model="model.costkm.endmeter" id="input_endmeter" type="number" placeholder="100449" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Date</label>
                            <input type="date" id="input_date" v-model="model.costkm.date" class="form-control" >
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" id="input_remark" v-model="model.costkm.remark"  placeholder="Remark...." />
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="addCostKM" style="float: right;" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <LorryCostKMTable :key="tableKey" />
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'CostKMCreate',
  data(){
    return {
        tableKey: 0,
    //   errorList: '',
      model:{
        costkm:{
            vehicleno:'',
            litre:'',
            startmeter:'',
            endmeter:'',
            fuelcost:'',
            drivercost:'',
            triphours:'',
            date:new Date().toISOString().slice(0,10),
            remark:''
        },
      }
    }
  },
  methods: {
    
    addCostKM(){
        // var mythis = this;
        axios.post('vehicle/addLorryTripCost',this.model.costkm)
            .then(res => {
                alert(res.data.message);
                this.model.costkm = {
                    vehicleno:'',
                    litre:'',
                    startmeter:'',
                    endmeter:'',
                    fuelcost:'',
                    drivercost:'',
                    triphours:'',
                    date:new Date().toISOString().slice(0,10),
                    remark:''
                };
                this.componentKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: vehicleno, litre, start odo meter, end odo meter, fuel cost and date is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>