<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Customer Info table</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder">
                  Message
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">
                  Date Created
                </th>
              </tr>
            </thead>
            <tbody>
              
              <tr v-for="(customerInfo,index) in this.customerInfo" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ customerInfo.message }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold" >
                    {{ customerInfo.created_at }}
                    </span>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
<script>
import axios from 'axios';

export default {
  name:'customerInfoSelect',
  data(){
    return {
        customerInfo: []
    }
  },
  mounted(){
    this.getCustomerInfoList();
  },
  methods: {

    getCustomerInfoList(){
        // var mythis = this;
        axios.get('customer/listCustomerInfoByCustomerID/' + this.$route.params.id)
            .then(res => {
                this.customerInfo = res.data.customerInfo;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
  },
}
</script>