<script setup>
// import { ref } from 'vue';
import LorryServiceTable from "./Tables/LorryServiceTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Services</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Vehicle No</label>
                            <input class="form-control" v-model="model.service.vehicleno" id="input_vehicleno" type="text" placeholder="KCB8028" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Services Type</label>
                            <select class="form-control" v-model="model.service.servicetype">
                                <option v-for="(lorryservicestype,index) in model.lorryservicestype" :key="index" :value="lorryservicestype.id">
                                    {{ lorryservicestype.service }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Amount</label>
                            <input class="form-control" v-model="model.service.amount" id="input_amount" type="number" placeholder="100.0" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Service Meter</label>
                            <input class="form-control" v-model="model.service.servicemeter" id="input_servicemeter" type="number" placeholder="100920" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Date</label>
                            <input type="date" id="input_date" v-model="model.service.servicedate" class="form-control" >
                        </div>
                        <div class="col-md-5">
                            <label for="example-text-input" class="form-control-label">Location</label>
                            <argon-input type="text" v-model="model.service.location" id="input_address" placeholder="Ah CHONG Bt5" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Reference</label>
                            <input class="form-control" v-model="model.service.reference" id="input_quantity" type="text" placeholder="Invoice..." />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" id="input_remark" v-model="model.service.remark"  placeholder="Remark...." />
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="addService" style="float: right;" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <LorryServiceTable :key="componentKey" />
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'serviceCreate',
  data(){
    return {
        componentKey: 0,
    //   errorList: '',
      model:{
        service:{
            vehicleno:'',
            servicetype:'1',
            remark:'',
            location:'',
            servicedate: new Date().toISOString().slice(0,10),
            amount:'',
            reference:'',
            servicemeter: ''
        },
        lorryservicestype:[]

      }
    }
  },
  mounted(){
    this.getLorryServicesType();
  },
  methods: {
    addService(){
        console.log(this.model.service);
        // var mythis = this;
        axios.post('vehicle/addLorryService',this.model.service)
            .then(res => {
                alert(res.data.message);
                this.model.service = {
                    vehicleno:'',
                    servicetype:'',
                    remark:'',
                    location:'',
                    servicedate:'',
                    amount:'',
                    reference:'',
                    servicemeter: ''
                };
                this.componentKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Vehicle no, Type, Date, and Meter is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    getLorryServicesType(){
        // var mythis = this;
        axios.get('vehicle/listservicestype')
            .then(res => {
                this.model.lorryservicestype = res.data.lorryservicestype;
                this.model.service.servicetype = '1';
                // console.log(this.model.lorryservicestype);
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>
