<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import Carousel from "./components/Carousel.vue";
import CategoriesList from "./components/CategoriesList.vue";

const vehicleListTitle = "Vehicele List";
const potongSawitTitle = "Schedule";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Monthly Sawit Buah (Tonne)"
              :value="ttlSalesBuah"
              :description="ttlSalesBuahDescription"
              :icon="{
                component: 'fa fa-chart-line',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Monthly Expenses (RM)"
              :value="ttlExpenses"
              :description="ttlExpensesDescription"
              :icon="{
                component: 'fa fa-dollar-sign',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Monthly Baja Expenses (RM)"
              :value="ttlBajaExpense"
              :description="ttlExpensesDescription"
              :icon="{
                component: 'fa fa-money-bill',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Monthly Vehicle Cost (RM)"
              :value="ttlServicesCost"
              :description="ttlExpensesDescription"
              :icon="{
                component: 'fa fa-truck',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <!-- <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            
          </div>
          <div class="col-lg-5">
            
          </div>
        </div> -->
        <div class="row">
          <div class="col-lg-7">
            <div class="row">
              <div class="col-lg-12 mb-lg-0 mb-4">
                <!-- line chart -->
                <div class="card z-index-2">
                  <gradient-line-chart
                    id="chart-line"
                    title="Sawit Buah"
                    :description="{ textChartIncrease }"
                    :chart="{
                      labels: [],
                      datasets: [
                        {
                          label: 'Tonne',
                          data: [],
                        },
                      ],
                    }"
                  />
                </div>
                <div class="row mt-4">
                  <div class="col-lg-12 mb-lg-0 mb-4">
                    <div class="card">
                      <div class="p-3 pb-0 card-header">
                        <div class="d-flex justify-content-between">
                          <h6 class="mb-2">Sawit Buah Potong</h6>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table align-items-center">
                          <tbody>
                            <tr v-for="(item, index) in sawitBuahList" :key="index">
                              <td>
                                <div class="px-2 py-1 d-flex align-items-center">
                                  <div class="ms-4">
                                    <p class="mb-0 text-xs font-weight-bold">
                                      Block:
                                    </p>
                                    <h6 class="mb-0 text-sm">{{ item.blockid }}</h6>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="text-center">
                                  <p class="mb-0 text-xs font-weight-bold">Name:</p>
                                  <h6 class="mb-0 text-sm">{{ item.blockname }}</h6>
                                </div>
                              </td>
                              <td>
                                <div class="text-center">
                                  <p class="mb-0 text-xs font-weight-bold">Tonne:</p>
                                  <h6 class="mb-0 text-sm">{{ item.weight }} Tonne</h6>
                                </div>
                              </td>
                              <td>
                                <div class="text-center col">
                                  <p class="mb-0 text-xs font-weight-bold">Date:</p>
                                  <h6 class="mb-0 text-sm">{{ item.sell_at }}</h6>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <categories-list
                    :categories="vehicleCategory" :title="vehicleListTitle" v-show="checkVehicle"
                  />
                </div>
                <div class="row mt-4" id="divID1">
                  <categories-list
                    :categories="nextSawitBuahSchedule" :title="potongSawitTitle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name:'DashboardSelect',
  data(){
    return {
      nextSawitBuahSchedule: [],
      // dashboardCard: [],
      vehicleCategory: [],
      textChartIncrease: '',
      sawitBuahList: [],
      ttlSalesBuah: 0,
      ttlExpenses: 0,
      ttlServicesCost: 0,
      ttlBajaExpense: 0,
      ttlSalesBuahDescription: "",
      ttlExpensesDescription: "",
      ttlServicesCostDescription: "",
      ttlBajaExpenseDescription: "",
      checkVehicle:true
    }
  },
  mounted(){
    this.setupDashboard();
  },
  methods: {
    setupDashboard(){
      axios.get('dashboard/getDashboadData')
            .then(res => {
            
            //set up dashboard card
            this.ttlSalesBuah = res.data.dashboardCard[0].ttlSalesBuah;
            this.ttlServicesCost = res.data.dashboardCard[0].ttlServicesCost;
            this.ttlBajaExpense = res.data.dashboardCard[0].ttlBajaExpense;
            this.ttlExpenses = res.data.dashboardCard[0].ttlExpenses;
            if(parseInt(res.data.dashboardGetChartDiffPercent[0].increment_percent) > 0){
              this.ttlSalesBuahDescription = "<span class='text-sm font-weight-bolder text-success' > +" + res.data.dashboardGetChartDiffPercent[0].increment_percent + " %</span> more than last month";
            }else{
              this.ttlSalesBuahDescription = "<span class='text-sm font-weight-bolder text-danger' > " + res.data.dashboardGetChartDiffPercent[0].increment_percent + " %</span> less than last month";
            }

            if(parseInt(res.data.cardPrecent[0].expenses_increment) > 0){
              this.ttlExpensesDescription = "<span class='text-sm font-weight-bolder text-danger' > +" + res.data.cardPrecent[0].expenses_increment + " %</span> more than last month";
            }else{
              this.ttlExpensesDescription = "<span class='text-sm font-weight-bolder text-success' > " + res.data.cardPrecent[0].expenses_increment + " %</span> less than last month";
            }

            if(parseInt(res.data.cardPrecent[0].services_increment) > 0){
              this.ttlServicesCostDescription = "<span class='text-sm font-weight-bolder text-danger' > +" + res.data.cardPrecent[0].services_increment + " %</span> more than last month";
            }else{
              this.ttlServicesCostDescription = "<span class='text-sm font-weight-bolder text-success' > " + res.data.cardPrecent[0].services_increment + " %</span> less than last month";
            }

            if(parseInt(res.data.cardPrecent[0].baja_increment) > 0){
              this.ttlBajaExpenseDescription = "<span class='text-sm font-weight-bolder text-danger' > +" + res.data.cardPrecent[0].baja_increment + " %</span> more than last month";
            }else{
              this.ttlBajaExpenseDescription = "<span class='text-sm font-weight-bolder text-success' > " + res.data.cardPrecent[0].baja_increment + " %</span> less than last month";
            }

            this.sawitBuahList = res.data.sawitBuahList;
            if(parseInt(res.data.dashboardGetChartDiffPercent[0].increment_percent) >= 0){
              this.textChartIncrease = "<i class='fa fa-arrow-up text-success'></i><span class='font-weight-bold'> " + res.data.dashboardGetChartDiffPercent[0].increment_percent + "% more</span> in last month";
            }else{
              this.textChartIncrease = "<i class='fa fa-arrow-down text-danger'></i><span class='font-weight-bold'> " + (parseInt(res.data.dashboardGetChartDiffPercent[0].increment_percent)*-1) + "% less</span> in last month";
            }
            //setup vehicle list by category
            if(res.data.vehicleDate.length > 0){

              (res.data.vehicleDate).forEach((item)=>{
                // console.log(item);
                let vehicleCategoryModel = {
                  icon: {
                    component: 'fa-solid fa-truck',
                    background: 'dark',
                    color:'red',
                  },
                  label: '',
                  description: '',
                };
                //item.category 0 > expired, 1 within 7 day, 2 within 20 days, 3 within this month, 4 others
                if(item.category == "0"){
                  vehicleCategoryModel.icon.background = "white"; 
                  vehicleCategoryModel.icon.component = "fa-solid fa-circle-exclamation"; 
                  vehicleCategoryModel.icon.color = "red"; 
                }else if(item.category == "1"){
                  vehicleCategoryModel.icon.background = "white"; 
                  vehicleCategoryModel.icon.component = "fa-solid fa-circle-exclamation"; 
                  vehicleCategoryModel.icon.color = "blue"; 
                }else if(item.category == "2"){
                  vehicleCategoryModel.icon.background = "white"; 
                  vehicleCategoryModel.icon.component = "fa-solid fa-circle-exclamation"; 
                  vehicleCategoryModel.icon.color = "black"; 
                }else if(item.category == "3"){
                  vehicleCategoryModel.icon.background = "white"; 
                  vehicleCategoryModel.icon.component = "fa-solid fa-truck"; 
                  vehicleCategoryModel.icon.color = "green"; 
                }else{
                  vehicleCategoryModel.icon.background = "white"; 
                  vehicleCategoryModel.icon.component = "fa-solid fa-circle-exclamation"; 
                  vehicleCategoryModel.icon.color = "black"; 
                }
                vehicleCategoryModel.label = item.vehicleno; 
                vehicleCategoryModel.description = "|Puspakom: " + item.puspakom + " |Insurance: " + item.insurance + " |Roadtax: " + item.roadtax; 
                this.vehicleCategory.push(vehicleCategoryModel);
                // console.log(this.vehicleCategory);
              });
            }else{
              this.checkVehicle = false;
              let mydivID = document.getElementById('divID1');
              mydivID.classList.remove("mt-4");
            }
            
            //set up next potong sawit
            (res.data.nextSawitBuahSchedule).forEach((item)=>{
              let nextSawitBuahModel = {
                icon: {
                  component: 'fa-solid fa-fa-tree',
                  background: 'white',
                  color:'red',
                },
                label: '',
                description: '',
              };
              //item.category 0 > expired, 1 within 7 day, 2 within 20 days, 3 within this month, 4 others
              if(parseInt(item.dayBal) < 1){
                nextSawitBuahModel.icon.background = "white"; 
                nextSawitBuahModel.icon.component = "fa-solid fa-circle-exclamation"; 
                nextSawitBuahModel.icon.color = "red"; 
              }else if(parseInt(item.dayBal) >1 && parseInt(item.dayBal) < 5){
                nextSawitBuahModel.icon.background = "white"; 
                nextSawitBuahModel.icon.component = "fa-solid fa-tree"; 
                nextSawitBuahModel.icon.color = "blue"; 
              }else if(parseInt(item.dayBal) >5 && parseInt(item.dayBal) <10){
                nextSawitBuahModel.icon.background = "white"; 
                nextSawitBuahModel.icon.component = "fa-solid fa-tree"; 
                nextSawitBuahModel.icon.color = "black"; 
              }else{
                nextSawitBuahModel.icon.background = "white"; 
                nextSawitBuahModel.icon.component = "fa-solid fa-tree"; 
                nextSawitBuahModel.icon.color = "black"; 
              }
              nextSawitBuahModel.label = item.blockid; 
              nextSawitBuahModel.description = "Next Date: " + item.nextdate + " |Day Bal: " + item.dayBal + " days";
              this.nextSawitBuahSchedule.push(nextSawitBuahModel);
            });
            }).catch(function (error){
                
                console.log('Error',error.message);
            });
    },
  }
}
</script>