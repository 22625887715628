<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Schedule Table</h6>
        <div class="row">
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Block ID</label>
                <input class="form-control" v-model="search.blockid" id="input_vehicleno" type="text" placeholder="KCB8028" />
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date From</label>
                <input type="date" id="input_date" class="form-control" v-model="search.datefrom">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date To</label>
                <input type="date" id="input_date" class="form-control" v-model="search.dateto">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label" style="width: 100%;">&nbsp;</label>
                <button class="btn btn-success " style="float:right;" id="btn_search" @click="getSawitBuahSchedule">Search</button>
            </div>
        </div>
      </div>
      <hr />
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Block ID
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Date
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Day
                </th>
                <!-- <th class="text-uppercase text-xs font-weight-bolder">
                  
                </th> -->
                <!-- <th class="text-secondary opacity-7"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sawitBuahSchedule,index) in this.sawitBuahSchedule" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0">{{ sawitBuahSchedule.blockid }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <span class="font-weight-bold" >
                    Next  : <span class="text-danger">{{ sawitBuahSchedule.nextdate }}</span><br />
                    Potong: <span class="text-primary">{{ sawitBuahSchedule.pdate }}</span>
                    </span>
                </td>
                <td class="align-middle">
                  <span class="font-weight-bold" >
                    <span v-if="sawitBuahSchedule.dayBal > '10' " class="text-primary">{{ sawitBuahSchedule.dayBal }}</span>
                    <span v-else class="text-danger">{{ sawitBuahSchedule.dayBal }}</span>
                  </span>
                </td>
                <!-- <td class="align-middle">
                  <button class="btn btn-danger font-weight-bold text-xs" @click="removeLorryService(lorryservices.id)">Remove</button>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
<script>
import axios from 'axios';

export default {
  name:'SawitBuatScheduleSelct',
  data(){
    return {
        sawitBuahSchedule: [],
        // removeLorrySevices: {
        //   id:''
        // },
        search:{
          dateto: new Date().toISOString().slice(0,10),
          datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
          blockid: '',
        }
    }
  },
  mounted(){
    this.getSawitBuahSchedule();
    // this.getLorryServicesType();
  },
  methods: {
    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    getSawitBuahSchedule(){
        // var mythis = this;
        var blockid = '%';
        if(this.search.blockid != ''){
            blockid = this.search.blockid;
        }
        console.log(blockid);
        axios.get('block/listSawitBuahSchedule/' + this.search.datefrom + "/" + this.search.dateto + "/" +  blockid)
            .then(res => {
                this.sawitBuahSchedule = res.data.sawitBuahSchedule;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    removeLorryService(id){
      if(confirm("Do you really want to remove this service records? ID - " + id)){
        this.removeLorrySevices.id = id;
        axios.post('vehicle/removeLorrySevices',this.removeLorrySevices)
            .then(res => {
                alert(res.data.message);
                this.removeLorrySevicesID = '';
                this.getLorryServices();
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: ID is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
      }
    }
  },
}
</script>