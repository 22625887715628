<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Trip Cost</h6>
        <div class="row">
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Block ID</label>
                <input class="form-control" v-model="search.blockid" id="input_vehicleno" type="text" placeholder="KCB8028" />
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date From</label>
                <input type="date" id="input_date" class="form-control" v-model="search.datefrom">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date To</label>
                <input type="date" id="input_date" class="form-control" v-model="search.dateto">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label" style="width: 100%;">&nbsp;</label>
                <button class="btn btn-success " style="float:right;" id="btn_search" @click="getTripCostKM">Search</button>
            </div>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Vehicle
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Trip
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Driver
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Odo Meter
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Details
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Costs/KM
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tripCost,index) in this.tripCost" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ tripCost.vehicleno }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">Fuel: RM {{ tripCost.fuelcost }}</h6>
                  <p class="text-xs text-secondary mb-0">Distence: {{ tripCost.distence }} KM</p>
                  <p class="text-xs text-secondary mb-0">{{ tripCost.litre }} L</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">RM {{ tripCost.drivercost }}</p>
                  <p class="text-xs text-secondary mb-0">{{ tripCost.triphours }} Hrs</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">Start: {{ tripCost.startmeter }}</p>
                  <p class="text-xs font-weight-bold mb-0">End: {{ tripCost.endmeter }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ tripCost.deliverydate }}</p>
                  <p class="text-xs text-secondary mb-0">{{ tripCost.remark }}</p>
                </td>
                <td>
                  <h6 class="text-xs text-secondary mb-0"> RM <b style="color:red" class="font-weight-bold">{{ tripCost.costkm }}</b> /KM</h6>
                  <h6 class="text-xs text-secondary mb-0">Inc. Driver: <br />RM <b style="color:green" class="font-weight-bold">{{ tripCost.driverfuelcostkm }}</b> /KM</h6>
                </td>
                <td class="align-middle">
                  <RouterLink :to="{path : '/editBlock/'+ tripCost.id}"
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit Block"
                    >Edit
                  </RouterLink>
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>

  
<script>
import axios from 'axios';

export default {
  name:'tripCostSelect',
  data(){
    return {
        tripCost: [],
        search:{
          dateto: new Date().toISOString().slice(0,10),
          datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
          vehicleno: '',
        }
    }
  },
  mounted(){
    this.getTripCostKM();
  },
  methods: {

    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    getTripCostKM(){
        var vehicleno = '%';
        if(this.search.vehicleno != ''){
            vehicleno = this.search.vehicleno;
        }
        axios.get('vehicle/listAllTripCost/' + this.search.datefrom + "/" + this.search.dateto + "/" +  vehicleno)
            .then(res => {
            //   console.log(res.data.block);
                this.tripCost = res.data.tripCost;
            }).catch(function (error){
                
              console.log('Error',error.message);
            });
    }
  },
}
</script>
  