<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Product Table</h6>
      </div>
      <hr />
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Product
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Price
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Detail
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Category
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  Update
                </th>
                <th class="text-uppercase text-xs font-weight-bolder">
                  
                </th>
                <!-- <th class="text-secondary opacity-7"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in this.productList" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ item.productsid }}. {{ item.name }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ item.price }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ item.detail }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ item.categoryname }}
                    </span>
                </td>
                <td class="align-middle">
                  <span class="text-xs font-weight-bold" >
                    {{ item.updated_at }}
                    </span>
                </td>
                <td class="align-middle">
                    <button class="btn btn-warning" data-bs-target="#editProductsModal" @click="getProductDetails(item.productsid)" data-bs-dismiss="modal">Edit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Edit Modal -->
<div class="modal fade" id="editProductsModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalToggleLabel2">Edit Product</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-2">
                    <label for="example-text-input" class="form-control-label">Product ID</label>
                    <input class="form-control" v-model="model.product.productsid" id="input_id" type="text" placeholder="id" disabled />
                </div>
                <div class="col-md-3">
                    <label for="example-text-input" class="form-control-label">Product Name</label>
                    <input class="form-control" v-model="model.product.name" id="input_id" type="text" placeholder="id" disabled />
                </div>
                <div class="col-md-3">
                    <label for="example-text-input" class="form-control-label">Unit Price (RM)</label>
                    <input class="form-control" v-model="model.product.price" id="input_weight" type="number" placeholder="17" />
                </div>
                <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Detail</label>
                    <input class="form-control" v-model="model.product.detail" id="input_price" type="text" placeholder="1 Tingkat,2 Tingkat" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Category</label>
                    <select class="form-control" v-model="model.product.category">
                        <option value="0">Sawit</option>
                        <option value="1">Getah</option>
                        <option value="2">Durian</option>
                        <option value="3">Baja</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="example-text-input" class="form-control-label">Is delete</label>
                    <select class="form-control" v-model="model.product.isdelete" id="input_isdelete">
                        <option value="0">Open</option>
                        <option value="1">Delete</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success " @click="updateProductDetails" >Update</button>
            <button class="btn btn-secondary" data-bs-dismiss="modal" @click="closeProductModel" >Close</button>
        </div>
        </div>
    </div>
</div>
  </template>
  
<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
export default {
  name:'productSelect',
  data(){
    return {
        VeditProductsModal: null,
        productList: [],
        model: {
          product:{
            productsid:'',
            name:'',
            category:'',
            isdelete:'0',
            detail:'',
            price:''
          }
        }
    }
  },
  mounted(){
    this.getAllProductList();
    // let VeditProductsModal = new Modal(document.getElementById('editProductsModal'));
  },
  methods: {
    getAllProductList(){
        // var mythis = this;
        axios.get('product/listProducts')
            .then(res => {
                this.productList = res.data.productslist;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert(error.response.status);
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    closeProductModel(){
        
        this.model.product.productsid = "";
        this.model.product.name = "";
        this.model.product.price = "";
        this.model.product.detail = "";
        this.model.product.category = "";
        this.model.product.isdelete = "";
        this.VeditProductsModal.hide();
    },
    updateProductDetails(){
      if(confirm("Do you really want to update this product? " + this.model.product.productsid + ". " + this.model.product.name)){
        
        axios.post('product/updateProduct',this.model.product)
            .then(res => {
                alert(res.data.message);
                this.model.product.productsid = "";
                this.model.product.name = "";
                this.model.product.price = "";
                this.model.product.detail = "";
                this.model.product.category = "";
                this.model.product.isdelete = "";
                
                this.VeditProductsModal.hide();
                this.getAllProductList();
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: ID is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
      }
    },
    getProductDetails(id){
        this.VeditProductsModal = new Modal(document.getElementById('editProductsModal'));
        this.VeditProductsModal.show();
        axios.get('product/getProductDetails/'+id)
            .then(res => {
                console.log(res.data.productdetails);
                this.model.product.productsid = res.data.productdetails[0].productsid;
                this.model.product.name = res.data.productdetails[0].name;
                this.model.product.price = res.data.productdetails[0].price;
                this.model.product.detail = res.data.productdetails[0].detail;
                this.model.product.category = res.data.productdetails[0].category;
                this.model.product.isdelete = res.data.productdetails[0].isdelete;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: ID is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
  },
}
</script>