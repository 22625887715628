<script setup>
// import { ref } from 'vue';
import BajaTable from "./Tables/BajaTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Baja</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Block Id</label>
                            <input class="form-control" v-model="model.baja.blockid" id="input_block_id" type="text" placeholder="A01" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Type</label>
                            <select class="form-control" v-model="model.baja.type">
                                <option value="1">Urea</option>
                                <option value="2">Compact</option>
                                <option value="3">Compound</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Quantity (Beg)</label>
                            <input class="form-control" v-model="model.baja.quantity" id="input_quantity" type="number" placeholder="100" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Weight (KG) / Beg</label>
                            <input class="form-control" v-model="model.baja.weight" id="input_weight" type="number" placeholder="50" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Date</label>
                            <input type="date" id="input_date" v-model="model.baja.date" class="form-control" >
                        </div>
                        <div class="col-md-4">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" id="input_remark" v-model="model.baja.remark"  placeholder="Remark...." />
                        </div>
                    </div>
                    <hr />
                    <h6>Baja Payment Info</h6>
                    <div class="row">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Item</label>
                            <input class="form-control" v-model="model.expenses.item" id="input_term" type="text" placeholder="Baja Urea" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Price</label>
                            <input class="form-control" v-model="model.expenses.price" id="input_amount" type="number" placeholder="80.00" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Is Paid</label>
                            <select name="select_ispay" id="select_ispay" @change="changeRemark($event.target.value)" v-model="model.expenses.ispaid" class="form-control">
                                <option value="1">New (buy from someone)</option>
                                <option value="0">Is Paid</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label for="example-text-input" class="form-control-label">Payment Remark</label>
                            <input class="form-control" v-model="model.expenses.remark" id="input_bankacc" type="text" placeholder="Payment Remark..." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="addBaja" style="float: right;" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <BajaTable :key="componentKey" />
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'bajaCreate',
  data(){
    return {
        componentKey: 0,
    //   errorList: '',
      model:{
        baja:{
            blockid:'',
            type:'1',
            remark:'',
            quantity:'',
            weight:'',
            date: new Date().toISOString().slice(0,10),
        },
        expenses:{
            blockid:'',
            type:'1',
            date: '',
            remark:'Beli dari orang kampong',
            item:'baja',
            price:'0',
            ispaid:'1'
        },

      }
    }
  },
  methods: {
    changeRemark(value){
        // console.log(value);
        if(value == "1"){
        // this.model.expenses.remark = "1";
        this.model.expenses.remark = "Beli dari orang kampong";
        }else{
            this.model.expenses.remark = "";
        }
        
    },
    addBaja(){
        // var mythis = this;
        axios.post('baja/addBaja',this.model.baja)
            .then(res => {
                this.addExpenses();
                alert(res.data.message);
                this.componentKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block ID, Quantity and Date is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    addExpenses(){
        // var mythis = this;
        this.model.expenses.blockid = this.model.baja.blockid;
        this.model.expenses.date = this.model.baja.date;
        console.log(this.model.expenses);
        axios.post('expenses/addExpensesBlock',this.model.expenses)
            .then(res => {
                alert(res.data.message);
                this.model.expenses = {
                    blockid:'',
                    type:'0',
                    remark:'Beli dari orang kampong',
                    item:'baja',
                    price:'0',
                    ispaid:'1'
                };
                
                this.model.baja = {
                    blockid:'',
                    type:'1',
                    remark:'',
                    quantity:'',
                    weight:'',
                    date: new Date().toISOString().slice(0,10),
                };
                this.componentKey += 1;
            }).catch(function (error){

                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block ID, Quantity and Date is required! ');
                    }else{
                        console.log(error.response.data.message);
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>