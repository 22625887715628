<script setup>
// import { computed } from "vue";
import { useRoute } from "vue-router";
// import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";
// import SidenavCard from "./SidenavCard.vue";

// const store = useStore();
//const isRTL = computed(() => store.state.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul style="list-style-type: none;">
      <li style="">
        <label for="example-text-input" class="form-control-label" style="font-size:15px">{{ model.user.name }}</label> 
      </li>
    </ul>
    <hr />
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard-default"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!-- <li class="nav-item">
        <sidenav-item
          to="/tables"
          :class="getRoute() === 'tables' ? 'active' : ''"
          :navText="'Tables'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/billing"
          :class="getRoute() === 'billing' ? 'active' : ''"
          :navText="'Billing'"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <!-- Sign In Tab -->
      <!-- <li class="nav-item">
        <sidenav-item
          to="/signin"
          :class="getRoute() === 'signin' ? 'active' : ''"
          :navText=" 'Sign In'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <!-- <li class="nav-item">
        <sidenav-item
          to="/signup"
          :class="getRoute() === 'signup' ? 'active' : ''"
          :navText="'Sign Up'"
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <li class="mt-3 nav-item">
   
        <h6
          v-on:click="hideShowNav('Account',visibleAccount)"
          class="text-xs ps-4 text-uppercase font-weight-bolder"
          :class="'ms-2'" style="width: 80%;cursor: pointer;"
        >
          ACCOUNT
          <span v-if="visibleAccount">
            <font-awesome-icon icon="fa-solid fa-minus text-dark text-sm" style="color:red; float: right;" />
          </span>
          <span v-else>
            <font-awesome-icon icon="fa-solid fa-plus text-dark text-sm" style="color:green; float: right;" />
          </span>
        </h6>
      </li>

      <!-- <li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="'Profile'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
      <div v-show="visibleAccount" class="navbar-nav">
        <li class="nav-item">
          <sidenav-item
            to="/users"
            :class="getRoute() === 'user' ? 'active' : ''"
            :navText="'Users'"
          >
            <template v-slot:icon>
              <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/customer"
            :class="getRoute() === 'customer' ? 'active' : ''"
            :navText="'Customer'"
          >
            <template v-slot:icon>
              <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
      </div>
      <li class="mt-3 nav-item">
        <h6
          v-on:click="hideShowNav('Sawit',visibleSawit)"
          class="text-xs ps-4 text-uppercase font-weight-bolder"
          :class="'ms-2'" style="width: 80%;;cursor: pointer;"
        >
          Sawit
          <span v-if="visibleSawit">
            <font-awesome-icon icon="fa-solid fa-minus text-dark text-sm" style="color:red; float: right;" />
          </span>
          <span v-else>
            <font-awesome-icon icon="fa-solid fa-plus text-dark text-sm" style="color:green; float: right;" />
          </span>
        </h6>
      </li>
      <div v-show="visibleSawit" class="navbar-nav">
        <li class="nav-item">
          <sidenav-item
            to="/block"
            :class="getRoute() === 'block' ? 'active' : ''"
            :navText="'Block'"
          >
            <template v-slot:icon>
              <i class="ni ni-square-pin text-dark text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/sawitbuah"
            :class="getRoute() === 'sawitbuah' ? 'active' : ''"
            :navText="'Sawit Buah'"
          >
            <template v-slot:icon>
              <font-awesome-icon icon="fa fa-list text-dark text-sm opacity-10" />
              
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/baja"
            :class="getRoute() === 'baja' ? 'active' : ''"
            :navText="'Baja'"
          >
          <template v-slot:icon>
            <i class="text-dark text-sm opacity-10"><font-awesome-icon icon="fa fa-leaf " /></i>
              
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/blockexpenses"
            :class="getRoute() === 'blockexpenses' ? 'active' : ''"
            :navText="'Expenses'"
          >
          <template v-slot:icon>
            <i class="text-dark text-sm opacity-10"><font-awesome-icon icon="fa fa-usd " /></i>
              
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/sewapajak"
            :class="getRoute() === 'sewapajak' ? 'active' : ''"
            :navText="'Sewa Pajak'"
          >
            <template v-slot:icon>
              <font-awesome-icon icon="fa fa-money-bill text-dark text-sm opacity-10" />
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/blockpnl"
            :class="getRoute() === 'blockpnl' ? 'active' : ''"
            :navText="'Block P&L'"
          >
            <template v-slot:icon>
              <i class="fa fa-dollar-sign text-dark text-sm opacity-10" ></i>
            </template>
          </sidenav-item>
        </li>
      </div>
      <!-- <li class="mt-3 nav-item">
        <h6
          v-on:click="hideShowNav('Order',visibleOrder)"
          class="text-xs ps-4 text-uppercase font-weight-bolder"
          :class="'ms-2'" style="width: 80%;;cursor: pointer;"
        >
          Orders
          <span v-if="visibleOrder">
            <font-awesome-icon icon="fa-solid fa-minus text-dark text-sm" style="color:red; float: right;" />
          </span>
          <span v-else>
            <font-awesome-icon icon="fa-solid fa-plus text-dark text-sm" style="color:green; float: right;" />
          </span>
        </h6>
      </li> -->
      <div v-show="visibleOrder" class="navbar-nav">
        <li class="nav-item">
          <sidenav-item
            to="/order"
            :class="getRoute() === 'order' ? 'active' : ''"
            :navText="'Create Order'"
          >
            <template v-slot:icon>
              <font-awesome-icon icon="fa-solid fa-cart-shopping text-dark text-sm opacity-10" />
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/orderlist"
            :class="getRoute() === 'orderlist' ? 'active' : ''"
            :navText="'Order List'"
          >
            <template v-slot:icon>
              <font-awesome-icon icon="fa-solid fa-list text-dark text-sm opacity-10" />
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/deliverylist"
            :class="getRoute() === 'deliverylist' ? 'active' : ''"
            :navText="'Delivery List'"
          >
            <template v-slot:icon>
              <font-awesome-icon icon="fa-solid fa-list text-dark text-sm opacity-10" />
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/product"
            :class="getRoute() === 'product' ? 'active' : ''"
            :navText="'Product'"
          >
            <template v-slot:icon>
              <font-awesome-icon icon="fa-solid fa-list text-dark text-sm opacity-10" />
            </template>
          </sidenav-item>
        </li>
      </div>
      <li class="mt-3 nav-item">
        <h6
          v-on:click="hideShowNav('Vehicle',visibleVehicle)"
          class="text-xs ps-4 text-uppercase font-weight-bolder"
          :class="'ms-2'" style="width: 80%;;cursor: pointer;"
        >
          Vehicle
          <span v-if="visibleVehicle">
            <font-awesome-icon icon="fa-solid fa-minus text-dark text-sm" style="color:red; float: right;" />
          </span>
          <span v-else>
            <font-awesome-icon icon="fa-solid fa-plus text-dark text-sm" style="color:green; float: right;" />
          </span>
        </h6>
      </li>
      <div v-show="visibleVehicle" class="navbar-nav">
        <li class="nav-item">
          <sidenav-item
            to="/vehicle"
            :class="getRoute() === 'vehicle' ? 'active' : ''"
            :navText="'Vehicle'"
          >
            <template v-slot:icon>
              <font-awesome-icon icon="fa-solid fa-truck text-dark text-sm opacity-10" />
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/lorryservices"
            :class="getRoute() === 'lorryservices' ? 'active' : ''"
            :navText="'Services'"
          >
            <template v-slot:icon>
              <font-awesome-icon icon="fa-solid fa-truck text-dark text-sm opacity-10" />
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/lorrycostkm"
            :class="getRoute() === 'lorrycostkm' ? 'active' : ''"
            :navText="'Cost/KM'"
          >
            <template v-slot:icon>
              <font-awesome-icon icon="fa-solid fa-dollar-sign text-dark text-sm opacity-10" />
            </template>
          </sidenav-item>
        </li>
      </div>
      <li class="mt-3 nav-item" v-on:click="hideShowNav('Loan',visibleLoan)">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder"
          :class="'ms-2'" style="width: 80%;;cursor: pointer;"
        >
          Sewa/Loan
          <span v-if="visibleLoan">
            <font-awesome-icon icon="fa-solid fa-minus text-dark text-sm" style="color:red; float: right;" />
          </span>
          <span v-else>
            <font-awesome-icon icon="fa-solid fa-plus text-dark text-sm" style="color:green; float: right;" />
          </span>
        </h6>
      </li>
      <div v-show="visibleLoan" class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/sewa"
          :class="getRoute() === 'sewa' ? 'active' : ''"
          :navText="'Sewa'"
        >
          <template v-slot:icon>
            <font-awesome-icon icon="fa-solid fa-dollar-sign text-dark text-sm opacity-10" />
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/loan"
          :class="getRoute() === 'loan' ? 'active' : ''"
          :navText="'Loan'"
        >
          <template v-slot:icon>
            <font-awesome-icon icon="fa-solid fa-dollar-sign text-dark text-sm opacity-10" />
          </template>
        </sidenav-item>
      </li>
      </div>
    </ul>
  </div>

</template>


<script>
import axios from 'axios';

export default {
  name:'authUser',
  data(){
    return {
      visibleLoan: false,
      visibleVehicle: false,
      visibleSawit: false,
      visibleAccount: false,
      visibleOrder: false,
      model:{
        user:{
            id:'',
            username:'',
            created_at:'',
            updated_at:'',
            isdelete:'',
            name:''
        },
      }
    }
  },
  mounted(){
    // this.model.block.blockid = this.$route.params.id;
    this.getAuthUser();
  },
  methods: {
    hideShowNav(nav, visible){
      this.visibleLoan= false;
      this.visibleVehicle= false;
      this.visibleSawit= false;
      this.visibleAccount= false;
      this.visibleOrder= false;
      if(nav == "Loan"){
        this.visibleLoan = !visible;
      }else if(nav == "Account"){
        this.visibleAccount = !visible;
      }else if(nav == "Vehicle"){
        this.visibleVehicle = !visible;
      }else if(nav == "Sawit"){
        this.visibleSawit = !visible;
      }else if(nav == "Order"){
        this.visibleOrder = !visible;
      }
    },
    getAuthUser(){
      console.log(localStorage.getItem('token'));
        // var mythis = this;
        axios.get('user/user')
            .then(res => {
              // console.log(res.data);
                this.model.user = res.data;
            }).catch(function (error){
                if(error.message == 'Unauthenticated.'){
                  alert('User Unauthenticated.Please login! ');
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>