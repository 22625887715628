<script setup>
// import { ref } from 'vue';
// import SawitBuahTable from "./Tables/SawitBuahTable.vue";
// import ProductTable from "./Tables/ProductTable.vue";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-md-7">
            <div class="card">
                <div class="card-header pb-0">
                    <h6>Products</h6>
                </div>
                <div class="container-fluid">
                    <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th class="text-uppercase text-xs font-weight-bolder">
                                Product
                                </th>
                                <th class="text-uppercase text-xs font-weight-bolder">
                                Variety
                                </th>
                                <th class="text-uppercase text-xs font-weight-bolder">
                                Price
                                </th>
                                <th class="text-uppercase text-xs font-weight-bolder">
                                Quantity
                                </th>
                                <th class="text-uppercase text-xs font-weight-bolder">
                                
                                </th>
                                <!-- <th class="text-secondary opacity-7"></th> -->
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in this.productList" :key="index">
                                <td>
                                    <div class="d-flex px-2 py-1">
                                        <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ item.productsid }}. {{ item.name }}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td class="align-middle">
                                    <input class="form-control" v-model="detailList[index]" id="input_variety" type="text" placeholder="variety" />
                                </td>
                                <td class="align-middle">
                                    <input class="form-control" v-model="priceList[index]" id="input_prices" type="number" placeholder="17" />
                                </td>
                                <td class="align-middle">
                                    <input class="form-control" v-model="quantityList[index]" id="input_quantity" type="number" placeholder="99" />
                                </td>
                                <td class="align-middle">
                                    <button class="btn btn-warning" @click="addCheckOut(index)" >Add</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="card" style="padding-bottom: 30px;">
                <div class="card-header pb-0">
                <h6>Customer Info</h6>
                </div>
                <div class="container-fluid">
                    <div class="row ">
                        <div class="col-md-12">
                            <label for="example-text-input" class="form-control-label">Company Profile</label>
                            <select class="form-control" v-model="customer.companyprofile">
                                <option value="1">Ang Chin Joo Nusery</option>
                                <option value="2">Ang Chin Joo Rubber & Palm Oil</option>
                                <option value="99">Others</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">Name</label>
                            <input class="form-control" v-model="customer.name" id="input_name" type="text" placeholder="ANG CHIN JOO" />
                        </div>
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">Company</label>
                            <input class="form-control" v-model="customer.company" id="input_weight" type="text" placeholder="ANG CHIN JOO NUSERY SDN BHD" />
                        </div>
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">Contact</label>
                            <input class="form-control" v-model="customer.contact" type="text" placeholder="0168815145" />
                        </div>
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">IC</label>
                            <input class="form-control" v-model="customer.ic" type="text" placeholder="ic..." />
                        </div>
                        <div class="col-md-12">
                            <label for="example-text-input" class="form-control-label">Address</label>
                            <input class="form-control" v-model="customer.address" type="text" placeholder="1 Tingkat,2 Tingkat" />
                        </div>
                        <div class="col-md-4">
                            <div class="mt-3 d-flex">
                                <h6 class="mb-0">Delivery Info</h6>
                                <div class="form-check form-switch ps-0 ms-auto my-auto">
                                    <input
                                    class="mt-1 form-check-input"
                                    :class="' ms-auto'"
                                    type="checkbox"
                                    v-model="ischeckDO"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label for="example-text-input" class="form-control-label">Order Status</label>
                            <select class="form-control" v-model="customer.orderstatus">
                                <option value="0">Pending</option>
                                <option value="1">Half Payment</option>
                                <option value="2">Completed</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label for="example-text-input" class="form-control-label">Delivery Status</label>
                            <select class="form-control" v-model="customer.deliverystatus">
                                <option value="0">Pending</option>
                                <option value="1">Half Delivery</option>
                                <option value="2">Completed</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Checkout</h6>
                </div>
                <div class="container-fluid">
                    <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-xs font-weight-bolder">
                                    Product
                                    </th>
                                    <th class="text-uppercase text-xs font-weight-bolder">
                                    Price
                                    </th>
                                    <th class="text-uppercase text-xs font-weight-bolder">
                                    Total
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in this.selectProducts" :key="index">
                                    <td>
                                        <div class="d-flex px-2 py-1">
                                            <div class="d-flex flex-column justify-content-center">
                                            <h5 class="mb-0 ">{{ item.name }}</h5>
                                            <h5 class=" text-secondary mb-0">{{ item.detail }}</h5>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold" >
                                        <h5 class="font-weight-bold mb-0">RM {{ item.price }}</h5>
                                        <h5 class="font-weight-bold mb-0">Qty: {{ item.quantity }} pk/bag</h5>
                                        </span>
                                    </td>
                                    <td>
                                        <h4 class=" font-weight-bold" >
                                        RM {{ item.total }}
                                        </h4>
                                    </td>
                                    <td>
                                        <button class="btn btn-warning" @click="RemovedCheckout(index)" ><font-awesome-icon icon="fa-solid fa-trash text-dark text-sm opacity-10" /></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-md-6">
                            <h4 style="text-align: right">Total: </h4>
                        </div>
                        <div class="col-md-6">
                            <h4 style="text-align: right"><b class="text-xl">RM {{ totalPrices }} (Tax: RM {{totalTax}})</b></h4>          
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="padding-top:10px">
                            <button class="btn btn-success" @click="addOrder" style="float: right" id="btn_add">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'productCreate',
  data(){
    return {
        ischeckDO:false,
        componentKey: 0,
        productList:[],
        priceList:[],
        quantityList:[],
        detailList:[],
        customer:{
            name:'',
            contact:'',
            address:'',
            company:'',
            ic:'',
            price:0,
            tax:0,
            total:0,
            orderstatus:0,
            deliverystatus:0,
            companyprofile:1
        },
        product: {
            productsid:'',
            name:'',
            detail:'',
            price:0,
            quantity:0,
            total:0
        },
        totalPrices:0,
        totalTax:0,
        selectProducts:[],
        order:{
            customer:null,
            delivery:null,
            orderDetails:null,
            isCheckDO: null
        }
    }
  },
  mounted(){
    this.listProduct();
  },
  methods: {
    RemovedCheckout(index){
        this.selectProducts.splice(index, 1);
    },
    addCheckOut(index){
        let productObj = {
            productsid:'',
            name:'',
            detail:'',
            price:0,
            quantity:0,
            total:0
        }
        productObj.quantity = this.quantityList[index];
        productObj.detail = this.detailList[index];
        productObj.price = this.priceList[index];
        productObj.total = this.quantityList[index] * this.priceList[index] ;
        productObj.name = this.productList[index].name;
        productObj.productsid = this.productList[index].productsid;
        this.totalPrices = this.totalPrices + (this.quantityList[index] * this.priceList[index]);
        this.totalTax = (this.totalPrices *0.06).toFixed(2);

        this.selectProducts.push(productObj);
    },
    addOrder(){
        this.order.customer = this.customer;
        this.order.orderDetails = this.selectProducts;
        this.order.customer.total = this.totalPrices;
        this.order.customer.tax = this.totalTax;
        this.order.customer.price = (this.totalPrices - this.totalTax).toFixed(2);
        this.order.ischeckDO = this.ischeckDO;
        if(this.ischeckDO == true){
            this.order.delivery = this.customer;
        }
        console.log(JSON.stringify(this.order));
        axios.post('order/addOrder',this.order)
            .then(res => {
                alert(res.data.message);
                // this.uploadImage();
                // this.customer = null;
                this.customer.name = '';
                this.customer.contact = '';
                this.customer.address = '';
                this.customer.ic = '';
                this.customer.company = '';
                this.customer.orderstatus = 0;
                this.customer.deliverystatus = 0;
                this.customer.price = 0;
                this.customer.tax = 0;
                this.customer.total = 0;
                this.customer.companyprofile = 1;
                this.selectProducts = null;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block id, Weight and Date is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    listProduct(){
        axios.get('product/listProducts')
            .then(res => {
                console.log(res.data.productslist);
                this.productList = res.data.productslist;
                this.productList.forEach((value) => {
                    this.priceList.push(value.price);
                    this.detailList.push(value.detail);
                    this.quantityList.push(0);
                });
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert(error.response.status);
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>